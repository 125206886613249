import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import {onHandleNumberFieldChange} from '../../HelperClasses/SingletonClass';
import appTopBar from '../../assets/appTopBar.png';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import radioUncheckIcon from '../../assets/radioUncheck.png';
import Switch from 'react-switch';
import AppColor from '../../HelperClasses/AppColor';
import {addMixpanelEvent, MixPannelEnum} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import ToolTipViewBtn from '../../Component/ToolTipViewBtn';
import AppConstantsMessage from '../../Constants/AppConstantsMessage';

import {Gradient} from 'react-gradient';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class AddShippingMedthod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      status: true,
      default: true,
      name: '',
      dataLoad: false,
      id: '',
      updatingBool: false,
      rank: '',
      shippingMethodsArray: [],
      selectedShippingMethod: '',
      selectedShippingMethodID: '',
      type: '',
      note: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    shppingData: PropTypes.any,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
  };
  componentDidMount() {
    const catData = this.props.shppingData;
    const isEdit = this.props.isEdit;
    // console.log('isEdit =>', isEdit);
    if (isEdit) {
      if (!this.state.updatingBool) {
        // console.log('catData =>', catData)
        this.setState({
          name: catData['name'],
          default: catData['default'],
          status: catData['active'],
          id: catData['id'],
          rank: catData['order_by'],
          type: catData['type'],
          updatingBool: true,
          note: catData['note'] ?? '',
        });
      }
    }
    this.loadShippingMethodsApi();
  }
  loadShippingMethodsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.shippingMethod,
      'get',
      '',
      '',
    );
    this.setState({shippingMethodsArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['shipping_methods'];
      for (let i = 0; i < objectD.length; i++) {
        this.state.shippingMethodsArray.push(objectD[i]);
      }
      if (this.state.shippingMethodsArray.length != 0) {
        if (this.state.id !== '') {
          // console.log('this.state.type', this.state.type);
          let index = this.state.shippingMethodsArray.findIndex(
            x => x.type == this.state.type,
          );
          // console.log('index.type', index);
          if (index != -1) {
            let item = this.state.shippingMethodsArray[index];
            this.setState({
              selectedShippingMethod: item['name'],
              selectedShippingMethodID: item['id'],
            });
          }
        } else {
          // let item = this.state.shippingMethodsArray[0];
          // this.setState({ selectedShippingMethod: item['name'], selectedShippingMethodID: item['id'] });
        }
      }
    }
    this.setState({dataLoad: true});
  };
  addShippingMethodApi = async image => {
    var dict = {
      shipping_method_id: this.state.selectedShippingMethodID,
      name: this.state.name,
      active: this.state.status,
      default: this.state.default,
    };
    if (this.state.rank != 0) {
      dict['order_by'] = this.state.rank;
    }
    if (this.state.note != 0) {
      dict['note'] = this.state.note;
    }

    var path = APPURL.URLPaths.tenantShippingMethod;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.tenantShippingMethod + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({shipping_method: dict}),
    );
    // console.log('category', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      window?.FM?.trackCustomEvent('Shipment', {
        email: AppConstants.userEmail,
        shipping_method: dict,
      });
      this.props.backBtnAction(true);
      this.customAlert();
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event =
      this.state.id !== ''
        ? MixPannelEnum.editShippingMethods
        : MixPannelEnum.addShippingMethods;
    console.log('event', event);
    addMixpanelEvent(event, properties);
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({dataLoad: false});
    this.addShippingMethodApi();
  };
  didSelectDropDown = item => {
    // console.log('index', item);
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedShippingMethod: item['name'],
      selectedShippingMethodID: item['id'],
    });
  };

  // UI Renders
  renderSaveConfigBtn = () => {
    return (
      <View>
        <SaveButton
          saveBtn={() => this.submitBtnAction()}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={appConfigStyle.showDropDownViewStyle}>
          <FlatList
            data={this.state.shippingMethodsArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Text
          style={
            this.state.selectedShippingMethod == ''
              ? [appConfigStyle.placeholderTxtStyle, {width: '100%'}]
              : [appConfigStyle.txtFieldStyleDropDown, {width: '100%'}]
          }>
          {this.state.selectedShippingMethod == ''
            ? 'Select shipping method'
            : this.state.selectedShippingMethod}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? appConfigStyle.upArraowIconStyle
              : appConfigStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };

  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderPreviewView = () => {
    return (
      <View style={{width: 320, height: '100%'}}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <Text style={tableStyle.listTitleText}>Preview</Text>
          </View>
          <View style={PreviewStyleSheet.previewFrameStyle}>
            <View style={{justifyContent: 'center'}}>
              <Image style={{width: 240, height: 55}} source={appTopBar} />
              <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Text
                  style={[
                    CommonStyleSheet.plainSubTextStyle,
                    {color: 'white', marginTop: -24},
                  ]}>
                  Shipment Option
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  height: 5,
                  backgroundColor: colors.AppNewGreen,
                }}
              />
              <View style={{width: '100%', height: 10}} />
              <View style={PreviewStyleSheet.commonViewStyle}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Text style={PreviewStyleSheet.previewTitleStyle}>
                    {this.state.name}
                  </Text>
                  <Image
                    style={{width: 20, height: 20}}
                    source={radioUncheckIcon}
                  />
                </View>
              </View>
            </View>
            {/* <View style={PreviewStyleSheet.bottomBtnViewStyle} >
            <View style={PreviewStyleSheet.applyBtnViewStyle}>
              <Text style={{ color: 'white', fontSize: 12, fontWeight: '600', textAlign: 'center' }}>{'CTA'}</Text>
            </View>
          </View> */}
          </View>
        </View>
      </View>
    );
  };
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{height: windowHeight / 1.12}}>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={{flex: 1}}>
              <View style={{height: 10}} />
              <View
                style={[
                  commonStyle.configContainerViewStyle,
                  {zIndex: 19890010},
                ]}>
                <Text style={commonStyle.titleNameStyle}>Shipping methods</Text>
                <TouchableOpacity
                  style={[commonStyle.dropDownViewStyle, {width: '100%'}]}
                  onPress={() =>
                    this.setState({showDropDown: !this.state.showDropDown})
                  }>
                  <this.renderValueLabel id={0} />
                  <this.customDropDown id={0} />
                </TouchableOpacity>
              </View>
              <View style={{height: 10}} />
              <View style={commonStyle.configContainerViewStyle}>
                <this.titleLblRender title={'Name'} />
                <TextInput
                  style={[
                    commonStyle.txtFieldStyle,
                    {outline: 0, marginLeft: 0, width: '100%'},
                  ]}
                  value={this.state.name}
                  placeholder="Enter name"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({name: name})}
                />
              </View>
              <View
                style={[commonStyle.configContainerViewStyle, {zIndex: 10999}]}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={commonStyle.titleNameStyle}>Note</Text>
                  <ToolTipViewBtn
                    title={AppConstantsMessage.noteTitleToopTipMsg}
                    description={AppConstantsMessage.noteSubTitleToolTipMsg}
                  />
                </View>
                <TextInput
                  style={[
                    commonStyle.txtFieldStyle,
                    {
                      outline: 0,
                      minHeight: 120,
                      paddingTop: 10,
                      width: '99%',
                      marginLeft: 0,
                    },
                  ]}
                  value={this.state.note}
                  placeholder="Enter note"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={notes => this.setState({note: notes})}
                  multiline={true}
                />
              </View>
              <View style={{height: 10}} />
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Order</Text>
                <TextInput
                  style={[
                    commonStyle.txtFieldStyle,
                    {outline: 0, marginLeft: 0, width: '100%'},
                  ]}
                  value={this.state.rank}
                  placeholder="Enter order"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={txt =>
                    this.setState({rank: onHandleNumberFieldChange(txt)})
                  }
                />
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.switchViewStyle}>
                <Text style={commonStyle.titleNameStyle}>
                  Status(Not Active / Active)
                </Text>
                <View style={{width: itsMobileView ? '12%' : '20%'}}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() => this.setState({status: !this.state.status})}
                    checked={this.state.status}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
              <View style={commonStyle.switchViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Default</Text>
                <View style={{width: itsMobileView ? '12%' : '20%'}}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() =>
                      this.setState({default: !this.state.default})
                    }
                    checked={this.state.default}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
            </View>
            <View style={{height: 20, width: '100%'}} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  subViewStyle: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginBottom: 5,
    zIndex: 19292,
  },
});
