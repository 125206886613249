import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Image,
  Dimensions,
  Alert,
  Platform,
  SafeAreaView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import disableIcon from '../../assets/disableIcon.png';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import AddTax from './AddTax';
import EmptyListUI from '../../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import {checkUserRoleModule} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import {Gradient} from 'react-gradient';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import BackBtnView from '../../Component/BackBtnView';
import HelperLinkView from '../../Component/HelperLinkView';
import {withRouter} from '../../wrappers/withRouter';
import HelperVideoView from '../../Component/HelperVideoView';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class Taxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      taxesArray: [],
      dataLoad: false,
      addTaxBool: false,
      isEdit: false,
      taxID: 0,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      showFilterView: false,
      selectedType: 'All',
    };
  }
  static propTypes = {
    addSaveBtnAction: PropTypes.func,
    backBtnAction: PropTypes.func,
  };
  componentDidMount() {
    addMixpanelEvent(MixPannelEnum.tax, {type: MixPannelEnum.listView});
    this.loadTaxesApi();
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.TAXVIEW, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  //MARK:-  APIS Method 
  loadTaxesApi = async () => {
    this.state.taxesArray = [];
    const responseJson = await networkService.networkCall(
      `${APPURL.URLPaths.tax}/?type=tax`,
      'get',
    );
    console.log('commission', responseJson);
    if (responseJson['status'] == true) {
      var commissionD = responseJson['data']['taxes'];
      for (let i = 0; i < commissionD.length; i++) {
        this.state.taxesArray.push(commissionD[i]);
      }
      this.state.haveData = this.state.taxesArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  deleteTaxApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tax + '/' + id,
      'delete',
    );
    // console.log('currencies', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      AppToast('delete');
      this.loadTaxesApi();
    }
  };
  //MARK:-  Buttons 
  didSelectFilter(id) {
    this.state.selectedType = id;
    this.setState({showFilterView: false, dataLoad: false});

    this.loadTaxesApi(this.state.selectedType);
  }
  deleteTaxAction = index => {
    const r = window.confirm('Are you sure you want to delete this');
    if (r == true) {
      this.deleteTaxApi(index);
    }
  };

  showEntriesDropDown() {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectDropDown = item => {
    this.setState({showDropDown: false, selectedEntriesCount: item});
  };
  statusBtnAction = index => {
    // console.log('statusBtnAction', index);
    //alert(index);
    if (Platform.OS === 'web') {
      alert(index);
    } else {
      Alert.alert(index);
    }
  };
  addTaxBtnAction() {
    this.setState({addTaxBool: !this.state.addTaxBool});
    this.setState({isEdit: false});
    this.loadTaxesApi();
  }
  editButtonAction = index => {
    // console.log('taxID', index);
    this.setState({isEdit: true, taxID: index});
    this.setState({addTaxBool: true});
  };
  /*  UI   */
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.dropDownViewStyle}>
          <FlatList
            data={['10', '25', '50', 'all']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 99989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[
              CommonStyleSheet.dropdownCellItemTextStyle,
              {
                color:
                  item == this.state.selectedEntriesCount
                    ? colors.AppTitleBlack
                    : colors.Appgray,
              },
            ]}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        keyExtractor={(item, index) => index + 29989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.taxesArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: 200}}>
            <EmptyListUI />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.taxesArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.taxesArray[i]['title'],
              this.state.taxesArray[i]['short_code'],
              this.state.taxesArray[i]['default_value'],
              this.state.taxesArray[i]['active'] == true
                ? 'Active'
                : 'Inactive',
              this.state.taxesArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={styles.lineViewStyle}
            keyExtractor={(item, index) => index + 9969}
            key={'C'}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 3) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item != 'Active'
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 4) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  renderEditBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.writeP}
        onPress={() => this.editButtonAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.writeP ? edit_Icon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderDeleteBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.deleteTaxAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? deleteIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderAddTaxn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.addTaxBtnAction()}>
              <Text style={tableStyle.saveBtnTxtStyle}>{'Add Tax'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  render() {
    if (this.state.addTaxBool) {
      return (
        <AddTax
          isEdit={this.state.isEdit}
          taxID={this.state.taxID}
          backBtnAction={() => this.addTaxBtnAction()}
        />
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={[tableStyle.navigationViewStyle, {zIndex: 110}]}>
            <View style={tableStyle.headerContainer}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity
                  style={tableStyle.backBtnBackViewStyle}
                  onPress={() => this.props.navigate(-1)}>
                  <BackBtnView />
                </TouchableOpacity>
                <View style={SettingStyleSheet.headerViewStyle}>
                  <Text style={tableStyle.titleTextStyle}>{`Taxes`}</Text>
                </View>
                <HelperVideoView
                  helperVideoBtnAction={() =>
                    window.open(AppConstants.TaxPageVideo, '_blank')
                  }
                />
              </View>
              <View style={{paddingLeft: 40, flexDirection: 'row'}}>
                {this.renderAddTaxn()}
              </View>
            </View>
          </View>
          <ToastContainer />
          <View style={[tableStyle.listContainerView, {zIndex: 10}]}>
            <ScrollView
              style={tableStyle.mainScrollViewStyle}
              horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView style={styles.tableViewHeaderStyle}>
                  <this.columnDataView />
                </ScrollView>
              </View>
              <Appload enable={this.state.dataLoad} />
            </ScrollView>
          </View>
          <HelperLinkView title={'Tax'} />
        </SafeAreaView>
      );
    }
    //}
  }
}

export default withRouter(Taxes);

let commonWidth = windowWidth / 5.2;
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : commonWidth,
    height: 40,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : commonWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  lineViewStyle: {
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
  },
});

const tableHeaderString = [
  'Title',
  'Short Code',
  'Default Value',
  'Status',
  'Action',
];
