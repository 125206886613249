import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  Image,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import searchIcon from '../assets/search.png';
import closeIcon from '../assets/closeIcon.png';
import settingGrayIcon from '../assets/settingGrayIcon.png';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import listIcon from '../assets/listIcon.png';
import gridIcon from '../assets/gridIcon.png';
import kanbanIcon from '../assets/kanbanIcon.png';
import locationIcon from '../assets/locationIcon.png';
import selectedListIcon from '../assets/selectedListIcon.png';
import selectedGridIcon from '../assets/selectedGridIcon.png';
import selectedkanbanIcon from '../assets/selectedkanbanIcon.png';
import selectedlocationIcon from '../assets/selectedlocationIcon.png';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {Gradient} from 'react-gradient';
import EmptyListUI from '../Component/AppEmptyList';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {AppToast} from '../HelperClasses/AppToast';
import ListingDetails from './ListingDetail';
import AddListing from './AddListings';
import whiteTick from '../assets/whiteTick.png';
import placeholder from '../assets/pPlaceholder.png';
import HelperLinkViews from '../Component/HelperLinkView';
import appMessage from '../Constants/AppConstantsMessage';
import Listing from '../Settings/Listings/Listings';
import AppConstants from '../Constants/AppConstants';
import {
  checkUserRoleModule,
  getThumbnailImage,
} from '../HelperClasses/SingletonClass';
import {ToastContainer} from 'react-toastify';
import Board from 'react-trello';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import {TwoThumbInputRange} from 'react-two-thumb-input-range';
import checked_Icon from '../assets/checkedIcon.png';
import unchecked_Icon from '../assets/uncheckedIcon.png';
import radioCheckIcon from '../assets/radioCheck.png';
import radioUncheckIcon from '../assets/radioUncheck.png';
import GoogleMapReact from 'google-map-react';
import {MODULEKEYS, ROLEMODULE} from '../Models/Enum';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import SegmentBtnView from '../Component/SegmentBtnView';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import TutorialView from '../Component/TutorialView';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import SecondryButton from '../Component/SecondryButton';
import SidePanView from '../Component/SidePanView';
import ImportListings from './ImportListings';
import SearchView from '../Component/SearchView';
import {RestrictedFeatureEnum} from '../Models/RestrictedFeatureEnum';
import SubscribePlan from '../Component/SubscribePlan';
import styles from '../StyleSheets/NewStyleSheet';
import copyPasteIcon from '../assets/copyPaste.png';
import GithubSlugger, {slug as slugChecker} from 'github-slugger';
import {Outlet} from 'react-router-dom';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let KeyGroup = 'general';

let itsMobileView = windowWidth < fixedWidth ? true : false;
let PerPage = 25;
let laneHeight = windowHeight / 1.55;
let dateFormat = 'yyyy-MM-DD';

const maxMinPrice = [0, 100000];

class MainListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: PerPage,
      showDropDown: false,
      showCategoryDropDown: false,
      selectedStatusType: 'All',
      selectedCategory: 'Select Category',
      selectedStatusID: 0,
      selectedCategoryID: -1,
      listingsArray: [],
      categoryArray: [],
      updateUI: false,
      dataLoad: false,
      stopPage: false,
      viewListingDetailBool: false,
      addListingBool: false,
      selectedID: 0,
      haveData: false,
      searchKey: '',
      typingTimeout: 0,
      addConfigs: false,
      showAddListingBtn: false,
      totalRecords: 0,
      statusArray: [],
      showKanbanView: false,
      showGridView: false,
      showListView: false,
      showMapView: false,
      showFilterView: false,
      showSortView: false,
      showSearchView: true,
      selectedFilterID: -1,
      selectedSortID: -1,
      sortParam: '',
      filterStartDate: '', //new Date(),
      filterEndDate: '', // new Date(),
      filterPriceValue: maxMinPrice,
      filterStatusValue: 0,
      appliedDate: false,
      appliedStatus: false,
      appliedPrice: false,
      appliedCategory: false,
      attributeArray: [],
      selectedCategoryArray: [],
      appliedAttribute: false,
      selectedAttributeArray: [],
      params: '',
      writeP: AppConstants.defaultWrite,
      moderationWrite: AppConstants.defaultWrite,
      showTutorialBool: false,
      showSidepanView: false,
      showUpgrade: false,
    };
  }
  componentDidMount() {
    this.getApi();
    this.checkModule();
    this.loadCategoryApi();
    // this.loadAttributeApi()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.LISTIINGS, res => {
      this.setState({writeP: res[1]});
    });
    checkUserRoleModule(MODULEKEYS.LISTIINGSMODERATION, res => {
      this.setState({moderationWrite: res[1]});
    });
  };

  getApi() {
    pageNo = 1;
    this.loadListingsApi();
  }
  loadListingsApi = async background => {
    this.setState({dataLoad: background ? true : false});
    var path = this.state.params;
    if (this.state.searchKey.length != 0) {
      path = path + `&search_key=${this.state.searchKey}`;
    }
    if (this.state.sortParam.length != 0) {
      path = path + `&sort=${this.state.sortParam}`;
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listings +
        pageNo +
        `${path}&per_page=${this.state.perPage}`,
      'get',
    );
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['listings'];
      this.state.totalRecords = responseJson['data']['total_records'];
      this.setState({listingsArray: []});
      if (listD.length != 0) {
        this.statusdata(listD);
        this.setState({stopPage: false});
        for (let i = 0; i < listD.length; i++) {
          this.state.listingsArray.push(listD[i]);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.listingsArray.length == 0 ? true : false;

      // * Mixpanel
      let view_type;
      if (this.state.showGridView) {
        view_type = MixPannelEnum.gridView;
      } else if (this.state.showKanbanView) {
        view_type = MixPannelEnum.kanbanView;
      } else if (this.state.showMapView) {
        view_type = MixPannelEnum.mapView;
      } else {
        view_type = MixPannelEnum.listView;
      }
      addMixpanelEvent(MixpanelEvent.viewListingsList, {
        page: pageNo,
        type: view_type,
      });
    } else {
      // this.state.haveData = this.state.listingsArray.length == 0 ? true : false
      this.alertView(responseJson);
    }
    this.setState({dataLoad: true});
  };
  statusdata(data) {
    this.state.statusArray = [];
    let ssArray = [];
    let pendingArray = [];
    let approvedArray = [];
    let rejectArray = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      var firstLine = item['description'].substring(0, 100);
      let dic = {
        id: item['id'],
        title: item['title'],
        description: firstLine,
        draggable: true,
      };
      if (item['status'] == 1) {
        pendingArray.push(dic);
      } else if (item['status'] == 2) {
        approvedArray.push(dic);
      } else if (item['status'] == 3) {
        rejectArray.push(dic);
      }
    }
    let pendingDic = {
      id: 'lane1',
      title: 'Pending',
      label: '',
      cards: pendingArray,
      style: {backgroundColor: colors.SimonGray, maxHeight: laneHeight},
    };
    let approvedDic = {
      id: 'lane2',
      title: 'Approved',
      label: '',
      cards: approvedArray,
      style: {backgroundColor: colors.CGreen, maxHeight: laneHeight},
    };
    let rejectDic = {
      id: 'lane3',
      title: 'Rejected',
      label: '',
      cards: rejectArray,
      style: {backgroundColor: colors.lightRed, maxHeight: laneHeight},
    };
    ssArray.push(pendingDic);
    ssArray.push(approvedDic);
    ssArray.push(rejectDic);
    this.state.statusArray.push({lanes: ssArray});
    this.setState({updateUI: !this.state.updateUI});
  }

  loadCategoryApi = async () => {
    let path = 'listings';
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.categories + path,
      'get',
      '',
      '',
    );
    this.setState({ListingArray: []});
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(
              hierarchyD[j],
              `${listD[i]['name']}-> ${hierarchyD[j]['name']}`,
            );
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(
                  hierarchy2[k],
                  `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`,
                );
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(
                      hierarchy3[l],
                      `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`,
                    );
                  }
                }
              }
            }
          }
        }
      }
      this.state.haveData = this.state.categoryArray.length == 0 ? true : false;
    } else {
      this.alertView(responseJson);
    }
    this.setState({dateLoad: true});
  };
  manageData(data, name) {
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = '';
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`;
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> ';
        }
      }
    }
    let catData = {
      id: data['id'],
      name: data['name'],
    };
    this.state.categoryArray.push(catData);
  }
  loadAttributeApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes + `?type=listings`,
      'get',
      '',
      '',
    );
    this.setState({attributeArray: []});
    if (responseJson['status'] == true) {
      var attriD = responseJson['data']['attributes'];
      console.log('attriD', attriD);
      for (let i = 0; i < attriD.length; i++) {
        this.state.attributeArray.push(attriD[i]);
      }
      this.state.haveData =
        this.state.attributeArray.length == 0 ? true : false;
    } else {
      this.alertView(responseJson);
    }
    this.setState({dataLoad: true});
  };
  aproveRejectListingsApi = async (id, type, background) => {
    this.setState({dataLoad: background ? true : false});
    let fDict = {
      status: type,
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listingsModerate + `/${id}/moderate`,
      'post',
      JSON.stringify({listing: fDict}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadListingsApi(background);
      if (background) {
      } else {
        this.alertView();
      }
    } else {
      this.alertView(responseJson);
    }
  };
  exportApi = async () => {
    let dict = {type: 'listings'};
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.exportListings,
      'post',
      JSON.stringify(dict),
    );
    if (responseJson['status'] == true) {
      let downloadLink = responseJson['data']['download_url'];
      window.open(downloadLink);
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast(title);
    } else {
      Alert.alert(title);
    }
  }
  onSearchTextChange = text => {
    this.setState({searchKey: text});
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(
        function () {
          this.getApi();
        }.bind(this),
        1000,
      ),
    });
  };
  onCancelText = () => {
    this.state.searchKey = '';
    this.getApi();
    this.setState({showSearchView: true});
  };
  //MAR:- Buttons Action
  viewListingBtnAction(id) {
    this.props.navigate(`/listing/${id}`);
  }
  backBtnHandler() {
    this.setState({
      addListingBool: false,
      viewListingDetailBool: false,
      addConfigs: false,
    });
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadListingsApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadListingsApi();
      }
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  showEntriesDropDown() {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectStatus = index => {
    this.state.selectedStatusType = StatusAry[index];
    this.state.selectedStatusID = index;
    this.setState({selectedStatusType: StatusAry[index]});
    // pageNo = 1;
    // this.loadListingsApi();
  };

  didSelectCategory = item => {
    let ind = this.state.selectedCategoryArray.findIndex(
      x => x.id == item['id'],
    );
    if (ind != -1) {
      this.state.selectedCategoryArray.splice(ind, 1);
    } else {
      this.state.selectedCategoryArray.push(item);
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  didSelectAttribute = item => {
    let ind = this.state.selectedAttributeArray.findIndex(
      x => x.id == item['id'],
    );
    if (ind != -1) {
      this.state.selectedAttributeArray.splice(ind, 1);
    } else {
      this.state.selectedAttributeArray.push(item);
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  statusBtnAction() {}
  addBtnAction(id) {
    if (id == 0) {
      this.setState({addConfigs: !this.state.addConfigs});
    } else {
      this.setState({addListingBool: !this.state.addListingBool});
    }
  }
  backBtnAction() {
    this.setState({viewListingDetailBool: false, addListingBool: false});
    this.getApi();
  }
  didSelectPerPage = (item, index) => {
    this.state.perPage = item;
    this.setState({showDropDown: false});
    this.getApi();
  };

  approveRejectBtnAction = (id, type) => {
    let msg = `Do you want to ${
      type == 2 ? 'approve' : 'reject'
    } this Listing ?`;
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.aproveRejectListingsApi(id, type);
      }
    }
  };
  onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    if (toLaneId == 'lane1') {
      this.aproveRejectListingsApi(cardId, 1, true);
    } else if (toLaneId == 'lane2') {
      this.aproveRejectListingsApi(cardId, 2, true);
    } else if (toLaneId == 'lane3') {
      this.aproveRejectListingsApi(cardId, 3, true);
    }
  };
  onCardClick = (cardId, metadata, laneId) => {
    this.setState({selectedID: cardId, viewListingDetailBool: true});
  };
  didSelectFilter(id) {
    this.setState({
      selectedFilterID: this.state.selectedFilterID == id ? -1 : id,
    });
    if (id == 3) {
      this.clearBtnAction(3);
    }
  }
  didSelectSort(id) {
    this.state.sortParam =
      this.state.selectedSortID == id ? '' : SortStaticArray[id]['key'];
    this.setState({
      selectedSortID: this.state.selectedSortID == id ? -1 : id,
      showSortView: false,
    });
    this.getApi();
  }
  applyBtnAction(id) {
    if (id == 0) {
      this.state.appliedStatus = true;
    }
    if (id == 1) {
      this.state.appliedPrice = true;
    }
    // if (id == 2) {
    //   this.state.appliedDate = true
    // }
    if (id == 2) {
      this.state.appliedCategory = true;
    }
    if (id == 4) {
      this.state.appliedAttribute = true;
    }
    if (!this.state.appliedDate) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
    }
    if (!this.state.appliedStatus) {
      this.state.filterStatusValue = -1;
    }
    if (!this.state.appliedPrice) {
      this.state.filterPriceValue = maxMinPrice;
    }
    if (!this.state.appliedCategory) {
      this.state.selectedCategory = [];
    }
    if (!this.state.appliedAttribute) {
      this.state.selectedAttributeArray = [];
    }
    this.state.params = '';
    if (this.state.appliedStatus) {
      this.state.params =
        this.state.selectedStatusID == 0
          ? ''
          : `&status=${this.state.selectedStatusID}`;
    }
    if (this.state.appliedPrice) {
      this.state.params =
        this.state.params +
        `&price_from=${this.state.filterPriceValue[0]}&price_to=${this.state.filterPriceValue[1]}`;
    }
    // if (this.state.appliedDate) {
    //   if (this.state.filterStartDate.length != 0 && this.state.filterEndDate.length != 0) {
    //     let strtD = Moment(this.state.filterStartDate).format(`${dateFormat}`)
    //     let endD = Moment(this.state.filterEndDate).format(`${dateFormat}`)
    //     this.state.params = this.state.params + `&start_at=${strtD}T00:00:00Z&end_at=${endD}T00:00:00Z`
    //   }
    // }
    if (this.state.appliedCategory) {
      if (this.state.selectedCategoryArray.length != 0) {
        var ids = [];
        for (let objc of this.state.selectedCategoryArray) {
          ids.push(objc['id']);
        }
        this.state.params =
          this.state.params + `&category_id=${ids.toString()}`;
      }
    }
    // if (this.state.appliedAttribute) {
    //   if (this.state.selectedAttributeArray.length != 0) {
    //     var ids = []
    //     for (let objc of this.state.selectedAttributeArray) {
    //       ids.push(objc['id'])
    //     }
    //     this.state.params = this.state.params + `&attribute_id=${ids.toString()}`
    //   }
    // }
    this.getApi();
    this.setState({showFilterView: false, selectedFilterID: -1});
  }
  clearBtnAction(id) {
    if (id == 0) {
      this.state.selectedStatusID = 0;
      this.state.selectedStatusType = StatusAry[0];
      this.state.appliedStatus = false;
    }
    if (id == 21) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
      this.state.appliedDate = false;
    }
    if (id == 1) {
      this.state.filterPriceValue = maxMinPrice;
      this.state.appliedPrice = false;
    }
    if (id == 2) {
      this.state.selectedCategoryArray = [];
      this.state.appliedCategory = false;
    }
    if (id == 4) {
      this.state.selectedAttributeArray = [];
      this.state.appliedAttribute = false;
    }
    if (id == 3) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
      this.state.filterPriceValue = maxMinPrice;
      this.state.filterStatusValue = -1;
      this.state.appliedStatus = false;
      this.state.appliedPrice = false;
      this.state.appliedDate = false;
      this.state.appliedAttribute = false;
      this.state.appliedCategory = false;
      this.state.selectedCategoryArray = [];
      this.state.selectedAttributeArray = [];
    }
    this.applyBtnAction();
    this.setState({showFilterView: false, selectedFilterID: -1});
  }
  fiterBtnAction() {
    if (!this.state.appliedDate) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
    }
    if (!this.state.appliedStatus) {
      this.state.filterStatusValue = 0;
    }
    if (!this.state.appliedPrice) {
      this.state.filterPriceValue = maxMinPrice;
    }
    this.setState({
      showFilterView: !this.state.showFilterView,
      selectedFilterID: -1,
      showSortView: false,
    });
  }
  onValueChange = values => {
    this.setState({filterPriceValue: values});
  };
  toolTipBtnAction() {
    this.setState({showTutorialBool: !this.state.showTutorialBool});
  }
  exportBtnAction() {
    this.exportApi();
  }
  importBtnAction() {
    this.setState({showSidepanView: !this.state.showSidepanView});
  }
  checkRestriction() {
    let check = AppConstants.restrictedFeatureArray.includes(
      RestrictedFeatureEnum.bulkEditListings,
    );
    return check;
  }
  copyBtnAction(item) {
    let id_Index = this.state.listingsArray.findIndex(x => x.id === item);
    let dict = this.state.listingsArray[id_Index];
    let slug = dict['slug'];
    var pathURL = AppConstants.domainKey;
    if (slug.length == 0) {
      let title = dict['title'];
      let vslug = slugChecker(title);
      pathURL = pathURL + `/l/${dict['id']}-${vslug}`;
    } else {
      pathURL = pathURL + `/l/${slug}`;
    }
    navigator.clipboard.writeText(pathURL);
    AppToast('Copied');
  }
  /*  UI   */
  renderKanbanBoard = () => {
    if (this.state.statusArray.length != 0) {
      return (
        <View style={{height: '100%'}}>
          <Board
            style={{backgroundColor: 'white', height: '100%'}}
            laneStyle={{height: windowHeight / 2}}
            data={this.state.statusArray[0]}
            cardDraggable={true}
            onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
            onCardClick={this.onCardClick}
            hideCardDeleteIcon={true}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };

  tableViewHeader = props => {
    return (
      <View style={styles.tableViewHeaderStyle}>
        <FlatList
          data={tableHeaderString}
          horizontal={true}
          renderItem={this.tableHeaderCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          style={{margin: 0}}
          keyExtractor={(item, index) => index + 989}
          key={'T'}
        />
      </View>
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View
        style={
          index == 0
            ? styles.firstTableViewTitleViewStyle
            : styles.main_listing_tableViewTitleViewStyle
        }>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.listingsArray.length; i++) {
      var aName = '';
      if (this.state.listingsArray[i]['account'] != null) {
        aName = this.state.listingsArray[i]['account']['name'];
      }
      console.log(this.state.listingsArray[i]);
      views.push(
        <FlatList
          data={[
            this.state.listingsArray[i]['id'],
            this.state.listingsArray[i]['id'],
            this.state.listingsArray[i]['list_price']['formatted'],
            this.state.listingsArray[i]['offer_percent'] + '%',
            this.state.listingsArray[i]['images'][0] || '',
            this.state.listingsArray[i]['offer_price']['formatted'],
            this.state.listingsArray[i]['active'],
            this.state.listingsArray[i]['id'],
            this.state.listingsArray[i]['id'],
            aName,
            this.state.listingsArray[i]['list_price']['currency'],
            this.state.listingsArray[i]['sold'] == true ? 'Yes' : 'No',
            this.state.listingsArray[i]['goal_price']['formatted'],
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          // keyExtractor={(item, index) => index + 9989}
          key={'C'}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 6) {
      var value = 'Active';
      if (index == 6) {
        value = item == true ? 'Active' : 'Inactive';
      }
      return (
        <View style={styles.main_listing_columnTitleViewStyle}>
          <Text
            style={
              item == 0
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {value}
          </Text>
        </View>
      );
    } else if (index == 8) {
      let id_Index = this.state.listingsArray.findIndex(x => x.id === item);
      let dict = this.state.listingsArray[id_Index];
      if (dict['status'] == 1) {
        return (
          <View style={styles.main_listing_columnTitleViewStyle}>
            <View style={{flexDirection: 'row', marginLeft: -5}}>
              <this.renderRejectView id={item} type={1} />
              <this.renderRejectView id={item} type={2} />
            </View>
          </View>
        );
      } else {
        return (
          <View style={styles.main_listing_columnTitleViewStyle}>
            <Text
              style={
                dict['status'] == 2
                  ? tableStyle.columnActiveBtnStyle
                  : tableStyle.columnDeActiveBtnStyle
              }>
              {dict['status'] == 2 ? 'Approved' : 'Rejected'}
            </Text>
          </View>
        );
      }
    } else if (index == 0) {
      let id_Index = this.state.listingsArray.findIndex(x => x.id === item);
      let name = this.state.listingsArray[id_Index]['title'];
      return (
        <TouchableOpacity
          style={styles.firstCellViewStyle}
          onPress={() => this.viewListingBtnAction(item)}>
          <Text style={tableStyle.themeTitleTxtStyle}> {name} </Text>
        </TouchableOpacity>
      );
    } else if (index == 13) {
      return (
        <TouchableOpacity
          style={styles.main_listing_columnTitleViewStyle}
          onPress={() => this.statusBtnAction(index)}>
          <Text
            style={
              item !== 'No'
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {'Reject'}
          </Text>
        </TouchableOpacity>
      );
    } else if (index == 4) {
      let fileURl = getThumbnailImage(item);
      return (
        <View style={styles.main_listing_columnTitleViewStyle}>
          <Image
            style={tableStyle.itemImageViewStyle}
            source={item.length == 0 ? placeholder : fileURl}
          />
        </View>
      );
    } else if (index == 1) {
      return (
        <View style={styles.main_listing_columnTitleViewStyle}>
          <TouchableOpacity onPress={() => this.copyBtnAction(item)}>
            <Image
              source={copyPasteIcon}
              style={{width: 15, height: 15, marginLeft: 10}}
            />
          </TouchableOpacity>
        </View>
      );
    } else {
      return (
        <View style={styles.main_listing_columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderRejectView = id => {
    return (
      <View style={styles.approveRejectViewStyle}>
        <View
          style={[
            tableStyle.secondButtonViewStyle,
            {borderColor: id['type'] == 1 ? colors.AppNewGreen : colors.AppRed},
          ]}>
          <TouchableOpacity
            disabled={!this.state.moderationWrite}
            onPress={() =>
              this.approveRejectBtnAction(id['id'], id['type'] == 1 ? 2 : 3)
            }>
            <Text
              style={[
                tableStyle.acceptBtnTextStyle,
                {color: id['type'] == 1 ? colors.AppNewGreen : colors.AppRed},
              ]}>
              {id['type'] == 1 ? 'Approve' : 'Reject'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  EmptyListRender = () => {
    if (this.state.listingsArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <EmptyListUI
              messageIcon={1}
              showImage={true}
              titleString={appMessage.emptyListingTitleString}
              subtitleString={appMessage.listingsMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  addListingButton = id => {
    return (
      <View
        style={{
          display: AppConstants.appType
            ? this.state.writeP
              ? 'flex'
              : 'none'
            : 'none',
        }}>
        <View style={[tableStyle.gradientViewBGStyle, {marginTop: -5}]}>
          <Gradient
            gradients={colors.GradientColors}
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity
              onPress={() => this.props.navigate('/add-listing')}>
              <Text style={tableStyle.saveBtnTxtStyle}>Add Listing</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  configButton = () => {
    return (
      <View
        style={[
          tableStyle.configurationBarViewStyle,
          {marginTop: itsMobileView ? 10 : 0},
        ]}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => this.setState({addConfigs: !this.state.addConfigs})}>
          <Image
            resizeMode={'cover'}
            style={{width: 16, height: 16, marginLeft: 16}}
            source={settingGrayIcon}
          />
          <Text style={[CommonStyleSheet.tabBtnViewStyle, {paddingLeft: 3}]}>
            {' Manage'}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  gradientButtons = name => {
    if (this.state.totalRecords > PerPage) {
      if (this.state.listingsArray.length != 0 || pageNo > 1) {
        if (name['id'] == 2 && pageNo == 1) {
          return <View />;
        } else if (
          name['id'] == 1 &&
          this.state.listingsArray.length == 0 &&
          pageNo > 1
        ) {
          return <View />;
        }
        return (
          <View>
            <View style={tableStyle.grandientBGStyle}>
              <Gradient
                gradients={colors.GradientColors} // required
                property="background"
                gradientType={'linear'}
                duration={2333000}
                style={{
                  height: 30,
                  width: 100,
                  borderRadius: 3,
                  marginRight: 30,
                }}
                angle="0deg">
                <TouchableOpacity
                  style={tableStyle.nextPreviousViewStyle}
                  onPress={() => this.nextPreviousBtnAction(name['id'])}>
                  <Text style={{color: 'white'}}>{name['name']}</Text>
                </TouchableOpacity>
              </Gradient>
            </View>
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.perPageContainerViewStyle}>
          <View style={{height: 4}} />
          <FlatList
            data={['25', '50', '100']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 989}
            key={'E'}
          />
        </View>
      );
    }
    return <View />;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectPerPage(item, index)}
        style={{margin: 3}}>
        <View
          style={[
            tableStyle.perPageDropDownViewStyle,
            {
              backgroundColor:
                item == this.state.perPage ? colors.BGBlueColor : '',
            },
          ]}>
          <Image
            source={whiteTick}
            style={{height: 16, width: item == this.state.perPage ? 16 : 0}}
          />
          <Text style={CommonStyleSheet.dropdownCellWhiteTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderPerView = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 20,
        }}>
        <Text>Show rows</Text>
        <View style={{flexDirection: 'column', zIndex: 9999}}>
          <TouchableOpacity
            onPress={() => this.showEntriesDropDown()}
            style={tableStyle.perPagedropDownView}>
            <Text> {this.state.perPage} </Text>
            <Image
              style={
                this.state.showDropDown == true
                  ? appConfigStyle.upArraowIconStyle
                  : appConfigStyle.downArraowIconStyle
              }
              resizeMode="contain"
              source={downArrow_Icon}
            />
          </TouchableOpacity>
          <this.entriesDropDown />
        </View>
      </View>
    );
  };
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (
        <View style={tableStyle.bottomViewStyle}>
          <this.gradientButtons name={'Next'} id={1} />
          <this.gradientButtons name={'Previous'} id={2} />
          <this.renderPerView />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderListView = () => {
    return (
      <ScrollView horizontal={true}>
        <View style={{width: '100%'}}>
          <View style={tableStyle.headerListContainer}>
            <ScrollView horizontal={true}>
              <this.tableViewHeader />
            </ScrollView>
          </View>
          <View style={{flex: 1, backgroundColor: 'white'}}>
            <View style={{height: '100%'}}>
              <ScrollView>
                <View style={{flex: 1, width: '100%', paddingLeft: 10}}>
                  <this.columnDataView />
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  };
  renderGridElements = () => {
    var view = [];
    for (let a = 0; a <= this.state.listingsArray.length - 1; a++) {
      let item = this.state.listingsArray[a];
      let photo = item['images'][0] || '';
      let active = item['active'];
      let fileURl = getThumbnailImage(photo);
      view.push(
        <TouchableOpacity
          style={tableStyle.gridContentViewsStyle}
          onPress={id => this.viewListingBtnAction(item['id'])}>
          <Image
            style={tableStyle.gridImageViewStyle}
            source={photo.length == 0 ? placeholder : fileURl}
          />
          <View style={{padding: 5}}>
            <Text style={tableStyle.themeTitleTxtStyle} numberOfLines={1}>
              {item['title']}
            </Text>
          </View>
          <View
            style={{
              padding: 5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text numberOfLines={1} style={tableStyle.gridPriceViewStyle}>
              {' '}
              {item['list_price']['formatted']}
            </Text>
            <View>
              <Text
                style={
                  active == 0
                    ? tableStyle.columnDeActiveBtnStyle
                    : tableStyle.columnActiveBtnStyle
                }>
                {active ? 'Active' : 'Inactive'}
              </Text>
            </View>
          </View>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  renderGridView = () => {
    return (
      <ScrollView>
        <View style={tableStyle.gridViewStyle}>
          <this.renderGridElements />
        </View>
      </ScrollView>
    );
  };
  renderMapView = () => {
    var marker = [];
    var lat = 0;
    var lng = 0;
    for (let objc of this.state.listingsArray) {
      let photo = objc['images'][0] || '';
      let fileURl = getThumbnailImage(photo);
      if (objc['coordinates']) {
        let coordinates = objc['coordinates'];
        if (coordinates['latitude']) {
          if (lat == 0) {
            lat = coordinates['latitude'];
            lng = coordinates['longitude'];
          }
          marker.push(
            <TouchableOpacity
              lat={coordinates['latitude']}
              lng={coordinates['longitude']}
              onPress={() => this.viewListingBtnAction(objc['id'])}>
              <Image
                style={{height: 50, width: 50, borderRadius: 10}}
                source={photo.length == 0 ? placeholder : fileURl}
              />
            </TouchableOpacity>,
          );
        }
      }
    }
    return (
      <View style={{flex: 1}}>
        <GoogleMapReact
          bootstrapURLKeys={{key: AppConstants.googleMapAPIKey}}
          defaultCenter={{lat: lat, lng: lng}}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals>
          {marker}
        </GoogleMapReact>
      </View>
    );
  };
  renderMainView = () => {
    if (this.state.showGridView) {
      return <this.renderGridView />;
    } else if (this.state.showMapView) {
      return <this.renderMapView />;
    } else if (this.state.showKanbanView) {
      return <this.renderKanbanBoard />;
    } else {
      return <this.renderListView />;
    }
  };
  renderHeaderBtn = () => {
    let showList =
      !this.state.showKanbanView &&
      !this.state.showGridView &&
      !this.state.showMapView;
    return (
      <View
        style={{
          flexDirection: 'row',
          marginRight: 10,
          zIndex: 100,
          marginTop: itsMobileView ? 10 : 5,
        }}>
        <View
          style={[
            styles.configurationBarViewStyle,
            {
              width: itsMobileView
                ? windowWidth / 1.1
                : AppConstants.appType
                ? windowWidth / 4
                : windowWidth / 3,
            },
          ]}>
          <View>
            <SegmentBtnView
              title={'List'}
              isSelected={showList}
              icon={showList ? selectedListIcon : listIcon}
              tapAction={() => {
                addMixpanelEvent(MixpanelEvent.viewListingsList, {
                  type: MixPannelEnum.listView,
                });
                this.setState({
                  showKanbanView: false,
                  showGridView: false,
                  showMapView: false,
                });
              }}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Map'}
              isSelected={this.state.showMapView}
              icon={
                this.state.showMapView ? selectedlocationIcon : locationIcon
              }
              tapAction={() => {
                addMixpanelEvent(MixpanelEvent.viewListingsList, {
                  type: MixPannelEnum.mapView,
                });
                this.setState({
                  showMapView: true,
                  showGridView: false,
                  showKanbanView: false,
                });
              }}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Board'}
              isSelected={this.state.showKanbanView}
              icon={this.state.showKanbanView ? selectedkanbanIcon : kanbanIcon}
              tapAction={() => {
                addMixpanelEvent(MixpanelEvent.viewListingsList, {
                  type: MixPannelEnum.boardView,
                });
                this.setState({
                  showKanbanView: true,
                  showGridView: false,
                  showMapView: false,
                });
              }}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Grid'}
              isSelected={this.state.showGridView}
              icon={this.state.showGridView ? selectedGridIcon : gridIcon}
              tapAction={() => {
                addMixpanelEvent(MixpanelEvent.viewListingsList, {
                  type: MixPannelEnum.gridView,
                });
                this.setState({
                  showGridView: true,
                  showKanbanView: false,
                  showMapView: false,
                });
              }}
            />
          </View>
        </View>
      </View>
    );
  };
  renderHeaderRightBtn = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          zIndex: 100,
          marginTop: itsMobileView ? 5 : 0,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          <View style={{position: 'relative'}}>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => this.fiterBtnAction()}>
              <Text
                style={[
                  CommonStyleSheet.tabBtnViewStyle,
                  {
                    padding: 0,
                    color: this.state.showFilterView
                      ? colors.AppNewGreen
                      : colors.Appgray,
                  },
                ]}>
                Filter
              </Text>
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: this.state.showFilterView
                  ? colors.AppNewGreen
                  : colors.TransparentColor,
                height: 2,
                width: '100%',
                marginTop: 5,
              }}
            />
            <this.renderFilterView />
          </View>
          <View style={{width: 20}} />
          <View>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() =>
                this.setState({
                  showSortView: !this.state.showSortView,
                  showFilterView: false,
                })
              }>
              <Text
                style={[
                  CommonStyleSheet.tabBtnViewStyle,
                  {
                    padding: 0,
                    color: this.state.showSortView
                      ? colors.AppNewGreen
                      : colors.Appgray,
                  },
                ]}>
                Sort
              </Text>
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: this.state.showSortView
                  ? colors.AppNewGreen
                  : colors.TransparentColor,
                height: 2,
                width: '100%',
                marginTop: 5,
              }}
            />
            <this.renderSortView />
          </View>
          <View style={{width: 10}} />
        </View>
      </View>
    );
  };
  renderSortView = () => {
    if (this.state.showSortView) {
      return (
        <View style={tableStyle.filterContainerViewStyle}>
          <View
            style={{
              display: this.state.showSortView ? 'flex' : 'none',
              flexDirection: 'row',
            }}>
            <View style={tableStyle.filterViewStyle}>
              <FlatList
                data={SortStaticArray}
                renderItem={this.renderSortCell}
                extraData={this.state}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index + 129}
                key={'FL'}
                style={{marginBottom: 10}}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSortCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectSort(index)}
        style={{
          marginLeft: 8,
          marginTop: 10,
          marginRight: 8,
          backgroundColor:
            this.state.selectedSortID == index
              ? colors.SimonGray
              : colors.TransparentColor,
          padding: 5,
          borderRadius: 2,
        }}>
        <Text>{item['value']}</Text>
      </TouchableOpacity>
    );
  };
  renderFilterView = () => {
    if (this.state.showFilterView) {
      var filterArray = [...FilterStaticArray];
      if (this.state.appliedStatus) {
        filterArray[0] = `Status(${1})`;
      }
      if (this.state.appliedPrice) {
        filterArray[1] = `Price(${1})`;
      }
      if (this.state.appliedCategory) {
        filterArray[2] = `Category(${this.state.selectedCategoryArray.length})`;
      }
      return (
        <View style={tableStyle.filterContainerViewStyle}>
          <View
            style={{
              display: this.state.showFilterView ? 'flex' : 'none',
              flexDirection: 'row',
            }}>
            <View style={tableStyle.filterViewStyle}>
              <FlatList
                data={filterArray}
                renderItem={this.renderFilterCell}
                extraData={this.state}
                showsVerticalScrollIndicator={false}
                // keyExtractor={(item, index) => index + 9}
                key={'FL'}
                style={{marginBottom: 10}}
              />
              <View
                style={{
                  height: '100%',
                  width: this.state.selectedFilterID == -1 ? 0 : 1,
                  backgroundColor: colors.SimonGray,
                }}
              />
              <this.renderStatusView />
              {/* <this.renderDateView /> */}
              <this.renderPriceSliderView />
              <this.renderCatAtrView id={1} />
              {/* <this.renderCatAtrView id={2}/> */}
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };

  renderClearApplyButton = id => {
    return (
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={() => this.clearBtnAction(id)}>
          <Text style={tableStyle.clearTxtStyle}>Clear</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.applyBtnAction(id)}>
          <Text style={tableStyle.applyTxtStyle}>Apply</Text>
        </TouchableOpacity>
      </View>
    );
  };
  renderFilterCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectFilter(index)}
        style={{
          marginLeft: 8,
          marginTop: 10,
          marginRight: 8,
          backgroundColor:
            this.state.selectedFilterID == index
              ? colors.SimonGray
              : colors.TransparentColor,
          padding: 5,
          borderRadius: 2,
        }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    );
  };
  renderStatusView = () => {
    var views = [];
    for (let a = 0; a < StatusAry.length; a++) {
      let item = StatusAry[a];
      views.push(
        <View>
          <TouchableOpacity
            style={{flexDirection: 'row', justifyContent: 'space-between'}}
            onPress={() => this.didSelectStatus(a)}>
            <Text>{item}</Text>
            <Image
              style={{width: 20, height: 20}}
              source={
                item == this.state.selectedStatusType
                  ? radioCheckIcon
                  : radioUncheckIcon
              }
            />
          </TouchableOpacity>
          <View style={{height: 5, width: 5}} />
        </View>,
      );
    }
    return (
      <View
        style={{
          width: 170,
          display: this.state.selectedFilterID == 0 ? 'flex' : 'none',
        }}>
        <View style={{margin: 10}}>
          <Text style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 5}]}>
            Status Types
          </Text>
          <View style={{marginTop: 12}}>{views}</View>
          <View style={{zIndex: 12, marginTop: 60}}>
            {this.renderClearApplyButton(0)}
          </View>
        </View>
      </View>
    );
  };

  renderPriceSliderView = () => {
    return (
      <View
        style={{
          width: 210,
          display: this.state.selectedFilterID == 1 ? 'flex' : 'none',
        }}>
        <View style={{margin: 10}}>
          <View>
            <Text style={[CommonStyleSheet.filterTitleStyle, {marginTop: 10}]}>
              Value Price
              {`: $${this.state.filterPriceValue[0]} - $${this.state.filterPriceValue[1]}`}
            </Text>
            <View style={{height: 20, width: 1}} />
            <TwoThumbInputRange
              showLabels={false}
              onChange={this.onValueChange}
              values={this.state.filterPriceValue}
              min={0}
              max={maxMinPrice[1]}
              inputStyle={{width: 185, height: 4}}
              trackColor={colors.AppNewGreen}
              railColor={colors.SimonGray}
              thumbStyle={{width: 20, height: 20}}
              thumbColor={colors.AppNewGreen}
            />
          </View>
          <View style={{zIndex: 12, marginTop: 145}}>
            {this.renderClearApplyButton(1)}
          </View>
        </View>
      </View>
    );
  };
  renderDateView = () => {
    let strtD =
      this.state.filterStartDate.length == 0
        ? 'Select Date'
        : Moment(this.state.filterStartDate).format(dateFormat);
    let endD =
      this.state.filterEndDate.length == 0
        ? 'Select Date'
        : Moment(this.state.filterEndDate).format(dateFormat);
    return (
      <View
        style={{
          width: 160,
          display: this.state.selectedFilterID == 2 ? 'flex' : 'none',
        }}>
        <View style={{zIndex: 126}}>
          <Text style={CommonStyleSheet.dateTitleStyle}>Start Date</Text>
          <View style={tableStyle.dateTextField}>
            <DatePicker
              selected={this.state.filterStartDate}
              onChange={date => this.setState({filterStartDate: date})}
              dateFormat="MM/dd/yyyy h:mm:ss"
              showTimeInput={false}
              customInput={
                <View style={{height: 20, width: 100}}>
                  <Text style={CommonStyleSheet.dateTitleStyle}>{strtD}</Text>
                </View>
              }
            />
          </View>
        </View>
        <View style={{zIndex: 123}}>
          <Text style={CommonStyleSheet.dateTitleStyle}>End Date</Text>
          <View style={tableStyle.dateTextField}>
            <DatePicker
              selected={this.state.filterEndDate}
              onChange={date => this.setState({filterEndDate: date})}
              dateFormat="MM/dd/yyyy h:mm:ss"
              showTimeInput={false}
              customInput={
                <View style={{height: 20, width: 100}}>
                  <Text style={CommonStyleSheet.dateTitleStyle}>{endD}</Text>
                </View>
              }
            />
          </View>
        </View>
        <View
          style={{zIndex: 12, marginLeft: 10, marginTop: 50, marginRight: 20}}>
          {this.renderClearApplyButton(2)}
        </View>
      </View>
    );
  };
  renderCatAtrView = ({id}) => {
    var views = [];
    let constAry =
      id == 1 ? this.state.categoryArray : this.state.attributeArray;
    let dynamicAry =
      id == 1
        ? this.state.selectedCategoryArray
        : this.state.selectedAttributeArray;
    var check = this.state.selectedFilterID == 2 ? true : false;
    if (id == 2) {
      check = this.state.selectedFilterID == 4 ? true : false;
    }
    let title = id == 2 ? 'Attributes' : 'Categories';
    for (let a = 0; a < constAry.length; a++) {
      let item = constAry[a];
      let ind = dynamicAry.findIndex(x => x.id == item['id']);
      views.push(
        <View>
          <TouchableOpacity
            style={{flexDirection: 'row', justifyContent: 'space-between'}}
            onPress={() =>
              id == 2
                ? this.didSelectAttribute(item)
                : this.didSelectCategory(item)
            }>
            <Text>{item['name']}</Text>
            <Image
              style={{width: 20, height: 20}}
              source={ind != -1 ? checked_Icon : unchecked_Icon}
            />
          </TouchableOpacity>
          <View style={{height: 5, width: 5}} />
        </View>,
      );
    }
    return (
      <View style={{width: 170, display: check ? 'flex' : 'none'}}>
        <View style={{margin: 10}}>
          <Text style={[CommonStyleSheet.filterTitleStyle, {marginTop: 5}]}>
            Listings {`${title}`}
          </Text>
          <ScrollView style={{height: 150, marginTop: 10}}>
            <View style={{marginTop: 12}}>{views}</View>
          </ScrollView>
          <View style={{zIndex: 12, marginTop: 20}}>
            {this.renderClearApplyButton(id == 2 ? 4 : 2)}
          </View>
        </View>
      </View>
    );
  };
  searchViewRender = () => {
    return (
      <View>
        <SearchView
          onSearchTextChange={text => this.onSearchTextChange(text)}
          searchKey={this.state.searchKey}
          onCancelText={this.onCancelText}
        />
      </View>
    );
  };
  renderImportExportBtnView = ({id}) => {
    return (
      <View style={{marginTop: itsMobileView ? 0 : -10}}>
        <SecondryButton
          showBtn={true}
          btnDeActive={this.checkRestriction()}
          btnTitle={id == 1 ? 'Import' : 'Export'}
          deActiveBtnAction={() => this.setState({showUpgrade: true})}
          btnAction={() =>
            id == 1 ? this.importBtnAction() : this.exportBtnAction()
          }
        />
      </View>
    );
  };
  renderSidePanView = () => {
    if (this.state.showSidepanView) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({showSidepanView: !this.state.showSidepanView})
          }
          showSidepanView={this.state.showSidepanView}
          customView={
            <View>
              <ImportListings />
            </View>
          }
          title={'Import'}
        />
      );
    } else {
      return <View />;
    }
  };
  renderUpgradePlanView = () => {
    return (
      <TouchableOpacity
        style={[
          tableStyle.passwordViewStyle,
          {display: this.state.showUpgrade ? 'flex' : 'none'},
        ]}
        onPress={() => this.setState({showUpgrade: false})}>
        <SubscribePlan upgrade={true} />
      </TouchableOpacity>
    );
  };
  render() {
    if (this.state.addListingBool) {
      return <AddListing backBtnAction={() => this.backBtnAction()} />;
    } else if (this.state.addConfigs) {
      return (
        <Listing
          titleString={'Listings'}
          backBtnAction={() => this.setState({addConfigs: false})}
        />
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <View style={{zIndex: 101}}>
            <this.renderSidePanView />
            {this.renderUpgradePlanView()}
          </View>
          <View style={{zIndex: 10}}>
            <View style={tableStyle.headerViewstyle}>
              <View
                style={{
                  width: 'auto',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
                  Listings{' '}
                </Text>
                <ToolTipViewBtn
                  title={AppConstantsMessage.listingTitleToopTipMsg}
                  description={AppConstantsMessage.listingsSubTitleToolTipMsg}
                />
              </View>
              <View
                style={{
                  flexDirection: itsMobileView ? 'column' : 'row',
                  flex: 1,
                  alignItems: 'center',
                  zIndex: 120,
                }}>
                <this.renderHeaderBtn />
              </View>
              <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <View
                  style={[
                    tableStyle.rightTopViewStyle,
                    {flexDirection: 'row', flexWrap: 'wrap'},
                  ]}>
                  <this.renderHeaderRightBtn />
                  <this.renderImportExportBtnView id={1} />
                  <this.renderImportExportBtnView id={2} />
                </View>
                <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
                  {/* <this.configButton /> */}
                  <this.searchViewRender />
                  <this.addListingButton />
                </View>
              </View>
            </View>
            <View style={tableStyle.mainView}>
              <View
                style={{
                  height: itsMobileView
                    ? this.state.totalRecords > PerPage
                      ? '85%'
                      : '95%'
                    : this.state.totalRecords > PerPage
                    ? '95%'
                    : '100%',
                }}>
                {this.renderMainView()}
                <this.EmptyListRender />
              </View>
              {this.renderBottomView()}
            </View>
            <Outlet />
            <HelperLinkViews title={'Listings'} />

            <Appload enable={this.state.dataLoad} />
          </View>
        </SafeAreaView>
      );
    }
  }
}

export default withRouter(MainListing);

const tableHeaderString = [
  'Title',
  // 'Slug',
  'Website',
  'List Price',
  'Offer Percent',
  'Image',
  'Offer Price',
  'Status',
  'Listing ID',
  'Listing Status',
  'Accounts',
  'Currency',
  'Sold',
  'Goal Amount',
];
const FilterStaticArray = [
  'Status',
  'Price',
  // 'Date',
  'Category',
  // 'Attribute',
  'Clear All Filters',
];
let StatusAry = ['All', 'Pending', 'Approved', 'Rejected'];
const SortStaticArray = [
  {key: 'newest_first', value: 'Newest First'},
  {key: 'price_high_to_low', value: 'Price - High to Low'},
  {key: 'price_low_to_high', value: 'Price - Low to High'},
  // {'key':'relevance','value': 'Relevance'},
];
