import React from 'react';
import {useNavigate} from 'react-router-dom';
import openLinkIcon from '../../assets/whiteOpenLinkIcon.png';

const SubMenu = ({sub_menu, main_menu}) => {
  const navigate = useNavigate();
  const menu_click = menu => {
    if (menu.href && (!menu?.sub_menus || menu?.sub_menus?.length == 0)) {
      menu?.isOpenNewTab
        ? window.open(menu.href, '_blank')
        : navigate(menu.href);
    }
  };

  return (
    <div className=" min-h-[150px]  w-[255px] rounded-lg  bg-primary text-white ">
      <p className=" text-white border-b border-white py-2 px-4 ">
        {main_menu.title}
      </p>

      <div className=" mt-3 px-4 flex flex-col gap-3 pb-3">
        {sub_menu?.map(menu => {
          return (
            <div
              className="flex items-center justify-start gap-4  cursor-pointer"
              onClick={() => menu_click(menu)}>
              <img className=" w-[30px] " src={menu.icon} alt="" />
              <div className="flex items-center justify-start">
                <p className=" min-w-[160px]">{menu.title}</p>
                {menu?.isOpenNewTab && (
                  <img className="h-4 " src={openLinkIcon} alt="" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubMenu;
