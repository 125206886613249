/* eslint-disable react/prop-types */
import React from 'react';

const AddressBox3 = ({order_details, shipment}) => {
  const type = order_details?.shipping_method.type;
  let address;

  if (type === 'pickup') {
    address = shipment?.pickup_address ?? {};
  } else {
    address = shipment?.shipping_address ?? {};
  }
  return (
    <div className="w-full    ">
      <div className="flex  ">
        <p className=" text-lg text-black font-semibold   ">
          {order_details?.shipping_method.name} Address
        </p>
      </div>
      <div className="mt-4">
        {Object?.keys(address)?.length > 0 ? (
          <>
            {address?.name && (
              <div className=" flex justify-start items-start py-1  ">
                <p className=" flex-none text-sm text-black font-semibold w-28 ">
                  Name :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.name}
                </p>
              </div>
            )}
            {address?.address_line_1 && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold w-28 ">
                  Address one :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.address_line_1}
                </p>
              </div>
            )}
            {address?.address_line_2 && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold w-28 ">
                  Address 2:
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.address_line_2}
                </p>
              </div>
            )}
            {address?.country && (
              <div className=" flex justify-start items-start py-1  ">
                <p className=" flex-none text-sm text-black font-semibold w-28">
                  Country:
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.country}
                </p>
              </div>
            )}
            {address?.post_code && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold w-28">
                  Zip code :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.post_code}
                </p>
              </div>
            )}
            {address?.formatted_address && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold w-28">
                  Formatted Address :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.formatted_address}
                </p>
              </div>
            )}
            {address?.phone_number && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold w-28">
                  Phone Number :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.phone_number}
                </p>
              </div>
            )}
          </>
        ) : (
          'Address is not available'
        )}
      </div>
    </div>
  );
};

export default AddressBox3;
