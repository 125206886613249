import React, {useState} from 'react';
import tlogo from '../assets/tradlyicon.png';
import {fixed_menus, sideMenus} from '../Constants/SideMenusArray';
import SideMenu from './SideMenu/SideMenu';
import {ICONS} from '../Constants/Icons';
import AppConstants from '../Constants/AppConstants';
import {B2CMenus} from '../Constants/B2CMenus';

const SideMenubar = ({isExpandSet, extra_styles}) => {
  const [menus, setMenus] = useState(
    AppConstants.appType ? B2CMenus : sideMenus,
  );
  const [isExpand, setIsExpand] = useState(
    isExpandSet === undefined ? true : isExpandSet,
  );

  return (
    <div
      className={`relative  h-[100dvh]  bg-primary overflow-y-auto overflow-x-visible flex flex-col py-2 ${
        isExpand ? 'w-[210px]' : 'w-14'
      } transition-all duration-300 ${extra_styles}`}>
      {/* logo */}
      <div
        className={` hidden  w-full  pb-3 sticky top-0 z-10 bg-primary md:flex items-center  gap-2  ${
          isExpand ? ' justify-start px-2' : 'justify-center '
        }`}>
        <button className="h-[30px] font-[FuturaLT-Book] w-[30px] md:h-[50px] md:w-[50px] cursor-text  ">
          {ICONS.tradly_logo}
        </button>
        {isExpand && (
          <p className="block  text-white text-xl font-bold  line-height-1  ">
            SuperAdmin
          </p>
        )}
      </div>

      {/* Menus */}
      <div className=" flex flex-col   flex-grow">
        {menus
          ?.filter(item => ![15, 17, 18].includes(item.id) && item.isShow)
          ?.map(item => {
            return <SideMenu menu={item} isExpand={isExpand} />;
          })}
      </div>

      {/* Sticky */}
      <div
        className={`  sticky bottom-0 bg-primary z-10 ${
          isExpand ? 'md:pb-6 ' : 'md:pb-12 '
        } `}>
        <div className="flex flex-col  flex-grow">
          {fixed_menus.map(item => {
            return <SideMenu menu={item} isExpand={isExpand} />;
          })}
        </div>

        {/* expand button */}
        <button
          className={` hidden md:flex absolute  bottom-0   w-full  ${
            isExpand ? 'justify-end px-4 ' : 'justify-center  pb-2 '
          }`}
          onClick={() => setIsExpand(!isExpand)}>
          <img
            className="   "
            src={isExpand ? ICONS.arrowFromRight : ICONS.arrowFromLeft}
            alt=""
          />
        </button>
      </div>
    </div>
  );
};

export default SideMenubar;
