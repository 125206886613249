import React, {useState} from 'react';
import AppNavigationHeader from '../../Component/AppNavigation';
import tlogo from '../../assets/tradlyicon.png';
import SideMenubar from '../DynamicSideMenubar';
import {ICONS} from '../../Constants/Icons';

const Header = () => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  return (
    <div className="w-full px-2  bg-white sticky top-0 z-50 flex items-center">
      <div className=" md:hidden  flex items-center gap-3 justify-start">
        <button className="" onClick={() => setIsShowSidebar(!isShowSidebar)}>
          <img
            className="w-8"
            src={isShowSidebar ? ICONS.closeIcon : ICONS.menu_Icon}
            alt=""
          />
        </button>
        <img className="w-8 h-8  " src={tlogo} alt="" />
      </div>
      <AppNavigationHeader />

      {isShowSidebar && (
        <div className=" fixed top-[48px] left-0 md:hidden  ">
          <SideMenubar
            isExpandSet={false}
            extra_styles={'h-[calc(100dVH-48px)] '}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
