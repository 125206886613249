import React from 'react';

const SecondaryButton = ({children, onClick}) => {
  return (
    <button
      className="px-4 py-2 bg-transparent text-primary border border-primary hover:text-white  hover:bg-primary duration-500 rounded-md  "
      onClick={onClick}>
      {children}
    </button>
  );
};

export default SecondaryButton;
