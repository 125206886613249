import React, {useEffect, useState} from 'react';
import NavigationHeader from '../../Shared/Header/NavigationHeader';
import {ICONS} from '../../Constants/Icons';
import {useNavigate, useSearchParams} from 'react-router-dom';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';
import AttributeGroupHeaderView from './AttributeGroupHeaderView';
import Modal from '../../Component/UI/modals/Modal';
import AttributeGroup from './AddAttributeGroupForm';
import Table from '../../UI/Table/Table';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {
  deleteAttributeGroup,
  loadAttributesGroup,
} from './actions/attributeGroupActions';
import placeholder from '../../assets/pPlaceholder.png';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import {ToastContainer} from 'react-toastify';
import AppEmptyList from '../../Component/AppEmptyList';
import EditAttributeGroupForm from './EditAttributeGroupForm';

const AttributesGroups = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get('type') ?? 'listings';
  const [isOpenForm, setOpenForm] = useState(false);
  const [isEditFormOpen, setEditFormOpen] = useState(false);
  const [selected_form_data, setSelected_form_data] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [groups_list, setGroups_list] = useState([]);

  //
  const load_attr_groups = async () => {
    setIsLoading(true);
    const list = await loadAttributesGroup({type: type});
    setIsLoading(false);
    if (list?.status) {
      setGroups_list(list?.data?.attribute_groups);
    }
  };

  //
  useEffect(() => {
    load_attr_groups();
  }, []);

  //
  const deleteHandler = async id => {
    setIsLoading(true);
    await deleteAttributeGroup({id});
    load_attr_groups();
  };

  return (
    <>
      <div className=" w-full     px-8  pb-10 mt-10 flex flex-col   max-h-[90%] min-h-[80VH] overflow-y-auto ">
        <AttributeGroupHeaderView onCreateAction={() => setOpenForm(true)} />

        {/*  */}
        {groups_list?.length > 0 && (
          <Table
            header_data={[
              {
                title: 'Name',
                key: 'name',
                className:
                  'text-sm font-medium text-start px-4 py-3    flex-grow',
              },
              {
                title: 'Image Path',
                key: 'name',
                className:
                  '  hidden md:block flex-grow text-sm  font-medium text-start px-4 py-3  ',
              },
              {
                title: 'Status',
                key: 'name',
                className:
                  '  flex-grow text-sm font-medium text-start px-4 py-3  ',
              },
              {
                title: 'Action',
                key: 'name',
                className:
                  '  flex-grow text-sm font-medium text-start px-4 py-3  ',
              },
            ]}
            table_header_style={' grid-cols-3 md:grid-cols-4'}>
            {/*  */}
            {groups_list?.map((group, index) => {
              return (
                <tr className="      w-full  grid  grid-cols-3 md:grid-cols-4 border-b border-[#e6e7e7] items-center ">
                  <td className=" min-w-[400px] flex-grow   py-2 px-4">
                    {group?.name}
                  </td>
                  <td className=" flex-grow  w-full  py-2 px-4 hidden md:block">
                    <img
                      className=" w-10 h-10 object-cover"
                      src={group?.icon_path ? group?.icon_path : placeholder}
                      alt=""
                    />
                  </td>
                  <td className="  flex-grow w-full   py-2 px-4">
                    {group?.active ? 'Active' : 'Inactive'}
                  </td>
                  <td className="  flex-grow w-full  py-2 px-4 flex items-center justify-start gap-2">
                    {/* <button
                      onClick={() => {
                        setEditFormOpen(true);
                        setSelected_form_data(group);
                      }}>
                      {
                        <img
                          className=" w-5 h-5 object-contain"
                          src={edit_Icon}
                          alt=""
                        />
                      }
                    </button> */}
                    <button onClick={() => deleteHandler(group?.id)}>
                      {
                        <img
                          className=" w-5 h-5 object-contain"
                          src={deleteIcon}
                          alt=""
                        />
                      }
                    </button>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}

        {/*  */}
        {(groups_list?.length == 0 || groups_list == undefined) && (
          <div className=" mt-40">
            <AppEmptyList showImage={true} />
          </div>
        )}

        {/*  */}
        <ToastContainer />
        {/* Apploader */}
        <AppLoader enable={!isLoading} />
      </div>
      {/*  */}
      {isOpenForm && (
        <Modal onCloseModal={() => setOpenForm(false)}>
          <div className=" max-w-[500px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 px-4 py-2">
            <AttributeGroup
              onCloseModal={() => {
                setOpenForm(false);
                load_attr_groups();
              }}
            />
          </div>
        </Modal>
      )}
      {isEditFormOpen && (
        <Modal
          onCloseModal={() => {
            setEditFormOpen(false);
            selected_form_data(null);
          }}>
          <div className=" max-w-[500px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 px-4 py-2">
            <EditAttributeGroupForm
              selected_data={selected_form_data}
              onCloseModal={() => {
                setEditFormOpen(false);
                selected_form_data(null);
                load_attr_groups();
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AttributesGroups;
