import {
  StyleSheet,
  View,
  UIManager,
  Text,
  Platform,
  Dimensions,
  TouchableOpacity,
  Image,
  Linking,
  SafeAreaView,
} from 'react-native';
import React, {Component} from 'react';
import SideMenubar from './SideBar';
import SideMenubar2 from './SideMenubar';
import RightViews from './RightView';
import AppHeader from '../Component/AppNavigation';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../HelperClasses/AppColor';
import menu_Icon from '../assets/menuIcon.png';
import tlogo from '../assets/tradlyicon.png';
import AppConstants from '../Constants/AppConstants';
import {StackActions} from '@react-navigation/native';
import {logout} from '../HelperClasses/SingletonClass';
import SubscribePlan from '../Component/SubscribePlan';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import closIcon from '../assets/closeBlackIcon.png';
import {Outlet} from 'react-router-dom';
import {withRouter} from '../wrappers/withRouter';
import DashboardScreens from '../Dashboard/DashboardScreen';
import StoreHome from '../Dashboard/StoreHome';
import {log} from 'logrocket';
import SideMenubar3 from './SideMenubar2';
import DSideMenubar from './DynamicSideMenubar';
import DashboardScreen2 from '../Dashboard/DashboardScreen2';

const APPConstants = require('../Constants/AppConstants');

const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let commonWidth = 200;

class RootView3 extends Component {
  //Main View defined under this Class
  constructor() {
    super();
    if (Platform.OS === 'android') {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    this.state = {
      updateValue: false,
      selectIndex: 0,
      showMenu: false,
      dataLoad: true,
      showQuickStart: false,
      changeEnv: true,
      reloadUI: false,
      reloadIndex: 0,
    };
  }
  logoutApi = async () => {
    // if(APPConstants.projectType == ProjectTypeModel.delivery){
    //   let link = AppConstants.deliveryRootURL.replace('superadmin', AppConstants.appMainURL)
    //   Linking.openURL(link)
    // } else{
    //   let link = AppConstants.marketPlaceRootURL.replace('superadmin', AppConstants.appMainURL)
    //   Linking.openURL(link)
    // }
    // Linking.openURL(APPConstants.APPLogout)
    logout();
  };
  //MARK:- Buttons Actions
  didSelectSideBarCell = id => {
    if (AppConstants.landingPage == id) {
      // this.props.navigation.navigate('LandingPage')
      this.props.navigation.dispatch(StackActions.replace('LandingPage'));
    } else {
      this.setState({selectIndex: id, reloadIndex: id});
      this.child.didTapBtn();
      this.setState({showMenu: !this.state.showMenu});
    }
  };
  showMenuBar() {
    this.setState({showMenu: !this.state.showMenu});
  }
  logoutButnAction() {
    this.setState({dataLoad: false});
    this.logoutApi();
  }
  changeEnvBtnAction(value) {
    this.state.changeEnv = value;
    this.state.reloadUI = true;
    this.state.selectIndex = -10;
    this.setState({updateValue: !this.state.updateValue});
  }
  reloadComponent() {
    this.state.reloadUI = false;
    this.setState({selectIndex: this.state.reloadIndex});
    this.setState({updateValue: !this.state.updateValue});
  }
  quickStartBtnAction() {
    // console.log('showQuickStart');
    this.setState({showQuickStart: !this.state.showQuickStart});
  }
  menuBtnClassAction() {
    this.showMenuBar();
  }
  //MARK:- UIs
  sideMenuUI = () => {
    if (windowWidth > fixedWidth) {
      return (
        <View style={styles.sidebarViewStyle}>
          <SideMenubar3
            selectIndex={this.state.selectIndex}
            changeEnvBtn={this.state.changeEnv}
            didSelectIndex={id => this.didSelectSideBarCell(id)}
            menubarWidth={commonWidth}
            customBtnFunction={() => this.props.ChangeRootView(1)}
          />
        </View>
      );
    } else {
      if (itsMobileView) {
        return (
          <View style={styles.sidebarViewStyle}>
            <SideMenubar3
              selectIndex={this.state.selectIndex}
              changeEnvBtn={this.state.changeEnv}
              didSelectIndex={id => this.didSelectSideBarCell(id)}
              menubarWidth={commonWidth}
              customBtnFunction={() => this.props.ChangeRootView(1)}
            />
          </View>
        );
      } else {
        return <View style={{backgroundColor: 'white', height: 0, width: 0}} />;
      }
    }
  };
  renderRightView = () => {
    if (this.state.showQuickStart) {
      return (
        <View
          style={{
            flex: 1,
            height: windowheight - 70,
            padding: 10,
            backgroundColor: colors.BackgroundBlueColor,
          }}>
          <RightViews />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderLogoView = () => {
    return (
      <View>
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
          <Image
            resizeMode={'contain'}
            style={styles.logoStyle}
            source={tlogo}
          />
        </View>
      </View>
    );
  };
  renderMobileLogo = () => {
    if (itsMobileView) {
      return (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <TouchableOpacity
            style={{marginLeft: 10}}
            onPress={() => this.menuBtnClassAction()}>
            <Image
              style={styles.menuBtnStyle}
              source={this.state.showMenu ? closIcon : menu_Icon}
            />
          </TouchableOpacity>
          <View style={{width: 10}} />
          {this.renderLogoView()}
        </View>
      );
    } else {
      return <View />;
    }
  };

  renderMainUI = () => {
    if (this.state.reloadUI) {
      return <View>{this.reloadComponent()}</View>;
    } else {
      let customWidth = this.state.showQuickStart ? 385 : commonWidth;
      return (
        <View style={{flex: 1, zIndex: 88987}}>
          <View style={tableStyle.navContainer}>
            {this.renderMobileLogo()}
            <View style={styles.headerView}>
              <AppHeader
                menuBtnAction={() => this.showMenuBar()}
                logoutBtnAction={() => this.logoutButnAction()}
                QuickStartBtn={() => this.quickStartBtnAction()}
                OpenMenuBar={this.state.showMenu}
                changeEvnBtn={value => this.changeEnvBtnAction(value)}
              />
            </View>
          </View>

          <View
            style={{
              zIndex: -1,
              height: windowheight,
              width:
                windowWidth > fixedWidth
                  ? windowWidth - customWidth
                  : windowWidth,
              backgroundColor: 'white',
            }}>
            <View
              style={{backgroundColor: 'white', width: '100%', height: '100%'}}>
              {this.props.location.pathname == '/' ? (
                AppConstants.appType ? (
                  <DashboardScreen2 />
                ) : (
                  <DashboardScreen2 />
                )
              ) : (
                <Outlet />
              )}
            </View>
          </View>
        </View>
      );
    }
  };

  renderSubscribePlanView() {
    return (
      <View
        style={[
          tableStyle.passwordViewStyle,
          {
            display: AppConstants.subscribed ? 'none' : 'flex',
            height: windowheight,
          },
        ]}>
        <SubscribePlan />
      </View>
    );
  }

  render() {
    return (
      <SafeAreaView>
        {this.renderSubscribePlanView()}
        <View style={styles.container}>
          <DSideMenubar />
          <View style={{flex: 1}}>
            <this.renderMainUI />
          </View>
          <Appload enable={this.state.dataLoad} />
        </View>
      </SafeAreaView>
    );
  }
}

export default withRouter(RootView3);

const styles = StyleSheet.create({
  submenuViewContainerStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    minHeight: windowheight,
    width: commonWidth,
    zIndex: 98882828,
    marginTop: 50,
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  headerView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    // height: 70,
    alignItems: 'center',
    // width: '100%',
    zIndex: 10000,
  },
  headerSubView: {
    margin: 10,
    alignItems: 'center',
  },
  sidebarViewStyle: {
    width: commonWidth,
    height: windowheight,
    zIndex: 999,
  },
  sideMenuViewStyle: {
    width: commonWidth,
    height: windowheight,
    zIndex: 999,
    position: 'absolute',
    marginTop: 50,
  },
  mainViewStyle: {
    // flex: 1,
    zIndex: 1,
    height: windowheight - 70,
    width: windowWidth > fixedWidth ? windowWidth - 385 : windowWidth,
    backgroundColor: 'pink',
  },
  logoStyle: {
    width: 30,
    height: 30,
    marginTop: itsMobileView ? 0 : 6,
  },
  menuBtnStyle: {
    width: 30,
    height: 30,
  },
});
