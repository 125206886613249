/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/prop-types */
import React from 'react';

import {useState} from 'react';
import {CONFIG_CONSTANT} from '../../../Constants/ConfigsConstants';
import {getThumbnailImage} from '../../../HelperClasses/SingletonClass';
import {currentShipmentStatus} from '../../../Constants/Status';
import OrderTimeline from '../OrderTimeline/OrderTimeline';
import AddressBox3 from '../AddressBox/AddressBox3';
import ShipmentOrderStatus from '../OrderStatus/OrderStatus2';

const ShipmentDetails2 = ({order_details, load_order_details}) => {
  //

  const [selected_shipment, setSelectedShipment] = useState(null);
  // Status Changing

  return (
    <div className="flex flex-col gap-6 ">
      {order_details?.shipments?.map((shipment, index) => {
        const OrderDetails = shipment?.order_details;

        return (
          <div className="w-full h-min-[300px] bg-white  shadow-c-sm rounded-card      p-4   sm:p-7   border-opacity-40  flex flex-col gap-4 ">
            <div className="flex items-center justify-between gap-4 flex-wrap">
              {shipment?.account?.name && (
                <div className="flex items-center justify-start  gap-2  flex-wrap ">
                  <p className="text-primary text-xl leading-6 font-medium  ">
                    Shipment {index + 1}
                  </p>

                  {shipment?.fulfilled_by_platform ? (
                    <p className="text-xl font-medium  ">
                      (Fulfilled by platform)
                    </p>
                  ) : shipment?.account?.name ? (
                    <p className="text-xl font-medium  ">
                      (Fulfilled by{' '}
                      <a
                        className=" underline hover:text-primary "
                        href={`/account/${shipment?.account?.id}`}>
                        {shipment?.account?.name}
                      </a>
                      )
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {/* Status change btn */}
              <div className="flex items-center justify-end gap-3 flex-wrap mt-2">
                <div className="flex items-center gap-2 ">
                  {/* <p>{Orders_text.orderlist.order_status} :</p> */}
                  <p className="    text-primary font-semibold">
                    {currentShipmentStatus(shipment.status)}
                  </p>
                </div>
                {/* Status change btn */}
                <ShipmentOrderStatus
                  order_details={order_details}
                  shipment={shipment}
                  load_order_details={load_order_details}
                  // setShowError={setShowError}
                  // setError_message={setError_message}
                  // setShowSuccess={setShowSuccess}
                  // setSuccess_message={setSuccess_message}
                />
              </div>
            </div>

            <div
              className={[
                'grid   gap-8 lg:gap-14  ',
                CONFIG_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !==
                true
                  ? '  grid-cols-1 lg:grid-cols-2'
                  : 'grid-cols-1',
              ].join(' ')}>
              {/* Items */}
              <div className="  flex flex-col gap-4   shadow-md  ">
                {/* Order Details items  */}
                <div className="flex flex-col gap-2">
                  {/* Header */}
                  <div
                    className={[
                      ' grid  grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%] pb-2 border-b-2 border-gray-400     bg-white z-20',
                    ].join(' ')}>
                    <p className=" pl-2 text-sm sm:text-lg text-black font-semibold ">
                      Items Summary
                    </p>
                    <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
                      QTY
                    </p>
                    <p className=" hidden md:block text-lg text-black font-semibold  text-center">
                      Price
                    </p>
                    <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                      Total Price
                    </p>
                  </div>
                  {/* Items */}
                  <div>
                    {OrderDetails?.map((item, index) => {
                      let listing_details = item?.listing;
                      let listing_variant_details = item?.variant
                        ? Object?.keys(item?.variant)?.length > 0
                          ? item?.variant
                          : undefined
                        : undefined;

                      let item_details = listing_variant_details
                        ? listing_variant_details
                        : listing_details;

                      return (
                        <a
                          href={`/listing/${item?.listing?.id}`}
                          className={[
                            '  grid  grid-cols-[50%,20%,30%]   md:grid-cols-[40%,20%,20%,20%] items-center py-3  pl-2 cursor-pointer',
                            index !== OrderDetails.length - 1 &&
                              'border-b-2 border-gray-400 border-opacity-20',
                          ].join(' ')}>
                          <div
                            className=" flex items-center ltr:pl-1 rtl:pr-1"
                            key={item.id}>
                            {item_details?.images?.length > 0 && (
                              <img
                                src={getThumbnailImage(item_details?.images[0])}
                                className="h-auto w-[50px] object-cover object-left-top"
                                alt="image"
                              />
                            )}
                            <div className="flex flex-col gap-2 ltr:ml-3 rtl:mr-3">
                              <p className=" text-sm font-semibold  ">
                                {listing_details.title}{' '}
                                {listing_variant_details &&
                                  listing_variant_details?.title &&
                                  `(${listing_variant_details.title})`}
                              </p>
                              {item?.listing?.seller_protection_fee_enabled && (
                                <span className=" text-xs  px-2 py-0.5 bg-AppLightOrange rounded-md text-primary">
                                  On Seller Protection Policy
                                </span>
                              )}
                              {item?.listing?.fulfilled_by_platform &&
                                CONFIG_CONSTANT.SHIPPING_CONFIGS
                                  .fulfillment_by_platform_enabled && (
                                  <img
                                    className=" w-auto h-auto max-w-[80px]"
                                    src={
                                      CONFIG_CONSTANT.SHIPPING_CONFIGS
                                        .fulfilled_by_platform_icon_path
                                    }
                                    alt="image"
                                  />
                                )}
                              {!item?.listing?.fulfilled_by_platform &&
                                CONFIG_CONSTANT.SHIPPING_CONFIGS
                                  .fulfillment_by_platform_enabled && (
                                  <img
                                    className="w-auto h-auto  max-w-[80px]"
                                    src={
                                      CONFIG_CONSTANT.SHIPPING_CONFIGS
                                        .fulfilled_by_seller_icon_path
                                    }
                                    alt="image"
                                  />
                                )}
                            </div>
                          </div>
                          <div>
                            <p className=" text-sm font-semibold  text-center">
                              X {item.quantity}
                            </p>
                          </div>
                          <div className=" hidden md:block">
                            <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                              {item.list_price.formatted}
                            </p>
                          </div>
                          <div>
                            <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                              {item.list_price.formatted}
                            </p>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="  grid grid-cols-1 md:grid-cols-7  gap-8 ">
                {/* Addrees */}
                {shipment &&
                  CONFIG_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !==
                    true && (
                    <div className=" col-span-1 md:col-span-4 shadow-md  rounded-card p-2 pt-0">
                      <AddressBox3
                        shipment={shipment}
                        order_details={order_details}
                      />
                    </div>
                  )}

                {/* Timeline */}
                {shipment?.shipment_status_history?.length > 0 &&
                  CONFIG_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !==
                    true && (
                    <div className=" col-span-1 md:col-span-3 ">
                      <OrderTimeline
                        shipment={shipment}
                        order_details={order_details}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShipmentDetails2;
