/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import networkService from '../Network/NetworkManager';
import ItemsSummary from './OrderDetails/ItemsSummary/ItemsSummary';
import ShipmentDetails from './OrderDetails/ShipementDetails/ShipmentDetails';
import CustomerDetails from './OrderDetails/CustomerDetails/CustomerDetails';
import OrderSummary from './OrderDetails/OrderSummary/OrderSummary';
import ShipmentDetails2 from './OrderDetails/ShipementDetails/ShipmentDetails2';
import NavigationHeader from '../Shared/Header/NavigationHeader';
import {ICONS} from '../Constants/Icons';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import {ToastContainer} from 'react-toastify';
import AppLoader from '../HelperClasses/AppLoaderClasses/AppLoader';
import SellerDetails from './OrderDetails/CustomerDetails/SellerDetails';
const APPURL = require('../Constants/URLConstants');

const OrderDetails2 = () => {
  const {orderID} = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();

  //Order Details state:
  const [orderDetailsState, setOrderDetailsState] = useState({
    isLoading: false,
    isError: false,
    error_msg: '',
    order_details: null,
  });

  //fetch order Detils by id

  useEffect(async () => {
    setOrderDetailsState(prev => ({
      ...prev,
      isLoading: true,
    }));
    load_order_details();
  }, [orderID]);

  // destructure state
  const {isLoading, isError, error_msg, order_details} = orderDetailsState;

  //load order details
  const load_order_details = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.order + `${orderID}`,
      'get',
    );

    if (responseJson?.status) {
      setOrderDetailsState(prev => ({
        ...prev,
        isLoading: false,
        isError: false,
        order_details: responseJson?.data?.order ?? {},
      }));
    } else {
      setOrderDetailsState(prev => ({
        ...prev,
        isLoading: false,
        isError: true,
        error_msg: responseJson,
      }));
    }
  };

  return (
    <div className=" w-full     px-8  pb-10 mt-10 flex flex-col   max-h-[90%] overflow-y-auto">
      <ToastContainer />
      <AppLoader enable={isLoading ? false : true} />
      <NavigationHeader className=" flex items-center justify-between">
        <div className="flex items-center gap-2 ">
          <button
            className=" text-primary rounded-full p-3 bg-[#F2F6F6]
          "
            onClick={() => navigate(-1)}>
            {ICONS.left_arrow}
          </button>
          <div className=" flex gap-2  " ref={contentRef}>
            <h2 className=" text-xl sm:text-3xl font-semibold text-black">
              Order Details
            </h2>
            {order_details?.reference_number && (
              <h2 className="  text-xl sm:text-3xl font-semibold text-primary  ">
                {`# ${order_details?.reference_number}`}
              </h2>
            )}
            <button
              className="text-primary "
              onClick={() => {
                navigator.clipboard.writeText(order_details?.reference_number);
                alert('Copied successfully');
              }}>
              {ICONS.copy}
            </button>
          </div>
        </div>
        <div>
          <ReactToPrint
            content={() => contentRef.current}
            documentTitle={order_details?.reference_number}>
            <PrintContextConsumer>
              {({handlePrint}) => (
                <button
                  className="bg-gradient-to-r from-[#01544E]  to-[#077B73]  shadow-sm text-white  text-sm font-normal px-3 py-2 rounded-md"
                  onClick={handlePrint}>
                  Generate
                </button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </NavigationHeader>

      {order_details && (
        <div ref={contentRef} className="">
          {/* Shipment details */}
          {!order_details?.legacy && (
            <div className="mt-6">
              <ShipmentDetails2
                order_details={order_details}
                load_order_details={load_order_details}
              />
            </div>
          )}
          {/*  */}
          <div className=" grid   grid-cols-[100%]  xl:grid-cols-[60%,40%]    mt-6">
            <div className="flex flex-col gpa-4">
              <CustomerDetails order_details={order_details} />
              <SellerDetails order_details={order_details} />
            </div>

            <div className="  mt-5 xl:mt-0 ltr:xl:ml-10 rtl:xl:mr-10">
              <OrderSummary order_details={order_details} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails2;
