import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Image,
  FlatList,
  SafeAreaView,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import AppColor from '../HelperClasses/AppColor';
import {addMixpanelEvent} from '../Models/MixPannelEnum';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import AppConstants from '../Constants/AppConstants';
import FilePicker from '../Component/FilePicker';
import {saveAs} from 'file-saver';
import SecondryButton from '../Component/SecondryButton';
import SaveButton from '../Component/SaveButton';
import {TypeEnum} from '../Models/CommonEnum';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
const APPURL = require('../Constants/URLConstants');

export default class ImportAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      showDropDown: false,
      dataLoad: true,
      selectedFile: '',
      errorMsg: '',
      loaderTitle: 'Uploading...',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
  };

  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedFile != null) {
      let fileName = this.state.selectedFile.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedFile['name'].replace(/ /g, ''),
          type: this.state.selectedFile['type'],
        };
        imgParm.push(dict);
      }
      this.setState({loaderTitle: 'Uploading...'});
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedFile.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          this.addBulkAccountApi(result[0]['fileUri']);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.addBulkAccountApi(
        this.state.selectedFile != null ? this.state.selectedFile : '',
      );
    }
  };

  addBulkAccountApi = async filePath => {
    this.setState({loaderTitle: 'Please wait...'});
    let aType = 'accounts';
    let params = JSON.stringify({file_path: filePath});
    const responseJson = await networkService.networkCall(
      `${APPURL.URLPaths.accounts}/import`,
      'POST',
      params,
    );
    this.state.errorMsg = '';
    if (responseJson['status'] == true) {
      this.props.backBtnAction();
    } else {
      var err = responseJson['data'];
      this.setState({errorMsg: `Error: ${err['error_message']}`});
    }
    this.setState({dataLoad: true});
  };
  generateAndDownloadFile = async () => {
    let link = AppConstants.bulkAccountsDownloadURL;
    let filename = `Accounts.csv`;
    saveAs(link, filename, res => {});
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(`${this.props.type} Bulk Accounts`, properties);
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({dataLoad: false});
    this.uploadImageAPI();
  };
  // onPick = file => {
  //   console.log('file', file)
  //   this.setState({ selectedFile: file })
  // }
  onPick = file => {
    // console.log('file', file)
    this.setState({selectedFile: file[0]});
  };
  downloadFileBtnAction() {
    this.generateAndDownloadFile();
  }
  //MARK:-   UI  

  renderDownloadFileView = id => {
    let title = 'Download';
    return (
      <SecondryButton
        btnTitle={title}
        btnAction={() => this.downloadFileBtnAction()}
      />
    );
  };
  selectedFileView = () => {
    let name =
      this.state.selectedFile.length == 0
        ? ''
        : this.state.selectedFile['name'].replace(/ /g, '');
    return (
      <View>
        <View
          style={[commonStyle.subViewStyle, {marginRight: 0, marginLeft: 10}]}>
          <Text style={commonStyle.titleNameStyle}>
            {'File: '}
            {name}
          </Text>
          <View>
            <View style={[tableStyle.secondButtonViewStyle]}>
              <FilePicker
                title={'Select File'}
                fileType={['.csv']}
                style={tableStyle.secondBtnTextStyle}
                data={files => this.onPick(files)}
              />
            </View>
          </View>
        </View>
        <View style={[commonStyle.subViewStyle]}>
          <Text style={[commonStyle.hintTitleStyle, {color: colors.AppRed}]}>
            {this.state.errorMsg}
          </Text>
        </View>
      </View>
    );
  };
  // UI Renders
  renderSaveConfigBtn = () => {
    return (
      <View>
        <SaveButton
          saveBtn={() => this.submitBtnAction()}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };
  render() {
    let aType = 'accounts';
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{height: windowHeight / 1.16}}>
          <View style={commonStyle.sidepanSaveBtnViewStyle}>
            {this.renderSaveConfigBtn()}
          </View>
          <ScrollView>
            <View style={commonStyle.sidepanmViewStyle}>
              <View style={{height: 20}} />
              <View
                style={[
                  commonStyle.subViewStyle,
                  {marginRight: 0, marginLeft: 10},
                ]}>
                <Text
                  style={
                    commonStyle.titleNameStyle
                  }>{`Download ${aType} template`}</Text>
                {this.renderDownloadFileView()}
              </View>
              <View style={{height: 10}} />
              {this.selectedFileView()}
              <View style={{height: 10}} />
            </View>
          </ScrollView>
        </View>
        <Appload
          titleMessage={this.state.loaderTitle}
          enable={this.state.dataLoad}
        />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  selectCategoryViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 20,
  },
  dropDownViewStyle: {
    marginTop: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: '100%',
  },
  selectAllTextStyle: {
    textDecorationLine: 'underline',
    color: colors.blueColor,
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    width: itsMobileView ? windowWidth / 1.2 : windowWidth / 2,
    marginLeft: itsMobileView ? 0 : -10,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 90,
    width: 90,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    height: 90,
    width: 90,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  iconImageViewStyle: {
    height: 20,
    width: 20,
  },
  categoryViewStyle: {
    backgroundColor: colors.SimonGray,
    padding: 5,
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 2,
    margin: 5,
  },
});

// Sandeep Harchand 78378-68008
