import React from 'react';

const Table = ({header_data, children, table_header_style, table_style}) => {
  return (
    <table
      className={[
        ' block border border-[#e6e7e7] rounded-md  max-h-[90%] max-w-full min-h-[400px] overflow-auto  mt-4  font-default',
        table_style,
      ].join(' ')}>
      <tr
        className={[' sticky top-0 z-10     grid ', table_header_style].join(
          ' ',
        )}>
        {/* table header */}
        {header_data?.map((item, index) => {
          return (
            <th
              className={[
                '   bg-blue-50  flex-grow font-default text-sm  font-medium',
                item.className,
              ].join(' ')}>
              {item?.title}
            </th>
          );
        })}
      </tr>

      {/* table data */}

      {children}
    </table>
  );
};

export default Table;
