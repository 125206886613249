import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import disableIcon from '../../assets/disableIcon.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import {AppToast} from '../../HelperClasses/AppToast';
import AddPaymentMenthods from './AddPaymentMenthod';
import HelperLinkViews from '../../Component/HelperLinkView';
import {Gradient} from 'react-gradient';
import PaymentConfigurations from './PaymentConfiguration';
import Translations from '../Listings/Translation';
import {TranslateEnum} from '../../Models/TranslateEnum';
import {checkUserRoleModule} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import AppColor from '../../HelperClasses/AppColor';
import {addMixpanelEvent, MixPannelEnum} from '../../Models/MixPannelEnum';
import PayU from './PayU';
import StripeIntegration from './StripeIntegration';
import settingGreenIcon from '../../assets/settingGreenIcon.png';
import {PaymentMethodEnum} from '../../Models/CommonEnum';
import BillPlzIntegration from './BillPlzIntegration';
import OPPSetup from './OPPSetup';
import BackBtnView from '../../Component/BackBtnView';
import SidePanView from '../../Component/SidePanView';
import {withRouter} from '../../wrappers/withRouter';
import RazorpaySettings from './RazorpaySettings';
import PayDunyaSettings from './PayDunyaSettings';
import MangoPaySettings from './MangoPay/MangoPaySettings';
import Subscription from '../Subscription/Subscription';
import Modal from '../../Component/UI/modals/Modal';
import PaymentMethodsConfig from './PaymentMethodsConfig';

const APPURL = require('../../Constants/URLConstants');

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

var pageNo = 1;
let KeyGroup = 'payments';

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      paymentMethodsArray: [],
      dataLoad: false,
      selectedPaymentIndex: 0,
      isEdit: false,
      addPaymentMethodBool: false,
      collectionID: 0,
      stopPage: false,
      haveData: false,
      selectedTabIndex: 0,
      statusValue: true,
      translationBool: false,
      configBool: false,
      translationTitle: '',
      translationRef: '',
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      stripeBool: false,
      razorpayBool: false,
      payDunyaBool: false,
      mangopayBool: false,
      payulatamBool: false,
      showOPPBool: false,
      billzPay: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.loadPaymentMethodsApi();
    this.checkModule();
    addMixpanelEvent(MixPannelEnum.paymentMethods, {
      type: MixPannelEnum.listView,
    });
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PAYMENTMETHOD, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  //MARK:-  APIS Method 
  loadPaymentMethodsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tenantPaymentMethod,
      'get',
      '',
      '',
    );
    this.setState({paymentMethodsArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['payment_methods'];
      console.log('objectD', objectD);
      for (let i = 0; i < objectD.length; i++) {
        this.state.paymentMethodsArray.push(objectD[i]);
      }
      this.state.haveData =
        this.state.paymentMethodsArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  deletePaymentMethodsApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tenantPaymentMethod + '/' + id,
      'delete',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.alertView('delete');
      this.loadPaymentMethodsApi();
    } else {
      this.mixpanelInfo(false);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event = `${MixPannelEnum.delete} ${MixPannelEnum.paymentMethods}`;
    addMixpanelEvent(event, properties);
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  addPaymentMethodsBtnAction() {
    this.setState({isEdit: false});
    this.setState({addPaymentMethodBool: !this.state.addPaymentMethodBool});
    this.loadPaymentMethodsApi();
  }
  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.paymentMethodsArray.findIndex(
      x => x.id === index,
    );
    this.setState({isEdit: true, selectedPaymentIndex: id_Index});
    this.setState({addPaymentMethodBool: true});
  };
  dltPaymentMethodBtnAction = index => {
    let msg = 'Do you want to go delete this Payment Method';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deletePaymentMethodsApi(index);
      }
    } else {
    }
  };
  addBtnAction() {
    if (this.state.selectedTabIndex == 0) {
      this.setState({
        isEdit: false,
        addPaymentMethodBool: !this.state.addPaymentMethodBool,
      });
    } else {
      this.child.saveBtnAction();
    }
  }
  settingBtnAction = index => {
    console.log(index);
    let id_Index = this.state.paymentMethodsArray.findIndex(
      x => x.id === index,
    );
    let item = this.state.paymentMethodsArray[id_Index];
    console.log(item);
    if (item['type'] == PaymentMethodEnum.stripe) {
      this.setState({stripeBool: true});
    } else if (item['type'] == PaymentMethodEnum.payulatam) {
      this.setState({payulatamBool: true});
    } else if (item['type'] == PaymentMethodEnum.billplz) {
      this.setState({billzPay: true});
    } else if (item['type'] == PaymentMethodEnum.opp) {
      this.setState({showOPPBool: true});
    } else if (item['type'] == PaymentMethodEnum.razorpay) {
      this.setState({razorpayBool: true});
    } else if (item['type'] == PaymentMethodEnum.paydunya) {
      this.setState({payDunyaBool: true});
    } else if (item['type'] == PaymentMethodEnum.mangopay) {
      this.setState({mangopayBool: true});
    }
  };

  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.paymentMethodsArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />;
      } else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.paymentMethodsArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.paymentMethodsArray[i]['name'],
              this.state.paymentMethodsArray[i]['min_amount'] || '',
              this.state.paymentMethodsArray[i]['max_amount'] || '',
              this.state.paymentMethodsArray[i]['default'],
              this.state.paymentMethodsArray[i]['order_by'],
              this.state.paymentMethodsArray[i]['active'],
              this.state.paymentMethodsArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: colors.SimonGray,
              backgroundColor: 'white',
            }}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 3 || index == 5) {
      return (
        <TouchableOpacity
          style={styles.columnTitleViewStyle}
          onPress={() => this.statusBtnAction(index)}>
          <Text
            style={
              item == false
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {index == 3 ? (item ? 'Yes' : 'No') : item ? 'Active' : 'Inactive'}
          </Text>
        </TouchableOpacity>
      );
    } else if (index == 6) {
      let ind = this.state.paymentMethodsArray.findIndex(x => x.id === item);
      let itm = this.state.paymentMethodsArray[ind];
      let type = itm['type'];
      let showSetting =
        type == PaymentMethodEnum.opp ||
        type == PaymentMethodEnum.stripe ||
        type == PaymentMethodEnum.payulatam ||
        type == PaymentMethodEnum.razorpay ||
        type == PaymentMethodEnum.paydunya ||
        type == PaymentMethodEnum.mangopay ||
        type == PaymentMethodEnum.billplz
          ? 'flex'
          : 'none';
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
            <View style={{width: 10}} />
            <View style={{display: showSetting}}>
              {this.renderSettingBtn(item)}
            </View>
          </View>
        </View>
      );
    } else if (index == 0) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  renderEditBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.writeP}
        onPress={() => this.editButtonAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.writeP ? edit_Icon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderDeleteBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.dltPaymentMethodBtnAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? deleteIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderSettingBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.settingBtnAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? settingGreenIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  viewHeader = props => {
    const tableHeaderString = ['Payment Methods']; //['Payment Methods', 'Configuration'];
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.headerCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        style={{marginLeft: 20}}
        scrollEnabled={true}
      />
    );
  };
  headerCell = ({item, index}) => {
    if (this.state.selectedTabIndex == index) {
      return (
        <TouchableOpacity
          style={styles.headerViewStyle}
          onPress={() => this.setState({selectedTabIndex: index})}>
          <Text style={tableStyle.titleTextStyle}>{`${item}`}</Text>
        </TouchableOpacity>
      );
    } else {
      return (
        <TouchableOpacity
          style={styles.selectedHeaderViewStyle}
          onPress={() => this.setState({selectedTabIndex: index})}>
          <Text style={tableStyle.titleTextStyle}>{`${item}`}</Text>
        </TouchableOpacity>
      );
    }
  };

  renderMainView = () => {
    if (this.state.selectedTabIndex == 1) {
      return (
        <PaymentConfigurations
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else {
      return (
        <View style={tableStyle.listContainerView}>
          <ToastContainer />
          <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
            <View>
              <View style={tableStyle.headerListContainer}>
                <this.tableViewHeader />
              </View>
              <ScrollView style={styles.tableViewHeaderStyle}>
                <this.columnDataView />
              </ScrollView>
            </View>
          </ScrollView>
        </View>
      );
    }
  };
  renderHelperView = () => {
    if (this.state.selectedTabIndex == 0) {
      return (
        <View>
          <Appload enable={this.state.dataLoad} />
          <HelperLinkViews title={'Payment Methods'} />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderAddPaymentBTN = () => {
    var addTitile =
      this.state.selectedTabIndex == 0
        ? 'Add Payment Method'
        : 'Save And Coutinue';
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.addBtnAction()}>
              <Text style={tableStyle.saveBtnTxtStyle}>{addTitile}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  renderTranslationBtn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity
            onPress={() => this.setState({translationBool: true})}>
            <Text style={tableStyle.secondBtnTextStyle}>
              {'Translate Payments'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderConfigsButton = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity onPress={() => this.setState({configBool: true})}>
            <Text style={tableStyle.secondBtnTextStyle}>{'Config'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderConfigsView = () => {
    if (this.state.addPaymentMethodBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({
              addPaymentMethodBool: !this.state.addPaymentMethodBool,
            })
          }
          showSidepanView={this.state.addPaymentMethodBool}
          customView={
            <View>
              <ToastContainer />
              <AddPaymentMenthods
                isEdit={this.state.isEdit}
                paymentMethodData={
                  this.state.paymentMethodsArray[
                    this.state.selectedPaymentIndex
                  ]
                }
                backBtnAction={() => this.addPaymentMethodsBtnAction()}
              />
            </View>
          }
          title={
            this.state.isEdit ? 'Edit Payment Method' : 'Add Payment Method'
          }
        />
      );
    } else if (this.state.showOPPBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({showOPPBool: !this.state.showOPPBool})
          }
          showSidepanView={this.state.showOPPBool}
          customView={
            <View>
              <OPPSetup />
            </View>
          }
          title={'Opp Integration'}
        />
      );
    } else if (this.state.billzPay) {
      return (
        <SidePanView
          dissmissView={() => this.setState({billzPay: !this.state.billzPay})}
          showSidepanView={this.state.billzPay}
          customView={
            <View>
              <BillPlzIntegration />
            </View>
          }
          title={'Bill Plz Integration'}
        />
      );
    } else if (this.state.stripeBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({stripeBool: !this.state.stripeBool})
          }
          showSidepanView={this.state.stripeBool}
          customView={
            <View>
              <StripeIntegration />
            </View>
          }
          title={'Stripe Integration'}
        />
      );
    } else if (this.state.razorpayBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({razorpayBool: !this.state.razorpayBool})
          }
          showSidepanView={this.state.razorpayBool}
          customView={
            <View>
              <RazorpaySettings
                closeSetting={() =>
                  this.setState({razorpayBool: !this.state.razorpayBool})
                }
              />
            </View>
          }
          title={'Razorpay settings'}
        />
      );
    } else if (this.state.payDunyaBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({payDunyaBool: !this.state.payDunyaBool})
          }
          showSidepanView={this.state.payDunyaBool}
          customView={
            <View>
              <PayDunyaSettings
                closeSetting={() =>
                  this.setState({payDunyaBool: !this.state.payDunyaBool})
                }
              />
            </View>
          }
          title={'Pay Dunya settings'}
        />
      );
    } else if (this.state.mangopayBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({mangopayBool: !this.state.mangopayBool})
          }
          showSidepanView={this.state.mangopayBool}
          customView={
            <View>
              <MangoPaySettings
                closeSetting={() =>
                  this.setState({mangopayBool: !this.state.mangopayBool})
                }
              />
            </View>
          }
          title={'Mangopay settings'}
        />
      );
    } else if (this.state.payulatamBool) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({payulatamBool: !this.state.payulatamBool})
          }
          showSidepanView={this.state.payulatamBool}
          customView={
            <View>
              <PayU />
            </View>
          }
          title={'PayU LATAM Integration'}
        />
      );
    } else {
      return <View />;
    }
  };

  render() {
    if (this.state.translationBool) {
      return (
        <ScrollView style={{flexDirection: 'column'}}>
          <ToastContainer />
          <Translations
            translationTitle={'Payments'}
            translationRef={TranslateEnum.paymentMethod}
            backBtnAction={() => this.setState({translationBool: false})}
          />
        </ScrollView>
      );
    } else {
      return (
        <>
          {' '}
          <SafeAreaView style={styles.Container}>
            <View style={{zIndex: 101}}>{this.renderConfigsView()}</View>
            <View style={{zIndex: 1}}>
              <View style={tableStyle.navigationViewStyle}>
                <View style={tableStyle.headerContainer}>
                  <View style={tableStyle.headerListViewcontainer}>
                    <TouchableOpacity
                      style={tableStyle.backBtnBackViewStyle}
                      onPress={() => {
                        this.props.navigate(-1);
                      }}>
                      <BackBtnView />
                    </TouchableOpacity>
                    <this.viewHeader />
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginLeft: itsMobileView ? 16 : 0,
                    }}>
                    {this.renderTranslationBtn()}
                    <View style={{width: 10}} />
                    {this.renderConfigsButton()}
                    <View style={{width: 10}} />
                    {this.renderAddPaymentBTN()}
                  </View>
                </View>
              </View>
              <View style={{flex: 1}}>
                <this.renderMainView />
              </View>
              <this.renderHelperView />
              {/* <this.renderMainView /> */}
              {/* <this.renderHelperView/> */}
              <Appload enable={this.state.dataLoad} />
            </View>
          </SafeAreaView>
          {/*  modal for configs */}
          {this.state.configBool && (
            <Modal onCloseModal={() => this.setState({configBool: false})}>
              <div className=" max-w-[700px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 px-4 py-2">
                <PaymentMethodsConfig
                  onCloseModal={() => this.setState({configBool: false})}
                />
              </div>
            </Modal>
          )}
        </>
      );
    }
  }
}

export default withRouter(PaymentMethods);

const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : windowWidth / 7.2,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / 7.2,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  saveBtnStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: 140,
    borderRadius: 5,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerViewStyle: {
    padding: 10,
    margin: 0,
  },
  selectedHeaderViewStyle: {
    padding: 10,
    height: 40,
    borderBottomColor: colors.TransparentColor,
    borderBottomWidth: 2,
    margin: 10,
  },
  MainContainerViewStyle: {
    flex: 1,
    marginTop: -20,
  },
});

const tableHeaderString = [
  'Name',
  'Minimum Amount',
  'Maximum Amount',
  'Default',
  'Order',
  'Status',
  'Action',
];
