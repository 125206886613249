import React, {useEffect, useState} from 'react';
import NavigationHeader from '../../Shared/Header/NavigationHeader';
import {useNavigate} from 'react-router-dom';
import {ICONS} from '../../Constants/Icons';
import SaveButton from '../../Component/SaveButton';
import Modal from '../../Component/UI/modals/Modal';
import Subscription from './Subscription';
import SecondryButton from '../../Component/SecondryButton';
import AddSubscriptionListing from './AddSubscriptionListing';
import SubscriptionList from './SubscriptionList';
import {ToastContainer} from 'react-toastify';
import getSubscriptionListings from '../../Actions/getSubscriptionListings';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';
import EditSubscriptionListing from './EditSubscriptionListing';

const SubscriptionLayout = () => {
  const navigate = useNavigate();
  const [isOpenConfigSidepan, setIsOpenConfigSidepan] = useState(false);
  const [isOpenSubscriptionSidepan, setIsOpenSubscriptionSidepan] =
    useState(false);
  const [subscription_list, setSubscription_list] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditSubscriptionSidepanOpen, setIsEditSubscriptionSidepanOpen] =
    useState(false);
  const [selected_subscription, setSelectedSubscription] = useState(null);

  // effect
  useEffect(() => {
    load_subscription_list();
  }, []);

  // loadSubscriptionList
  const load_subscription_list = async () => {
    setIsLoading(true);
    const list = await getSubscriptionListings();
    setIsLoading(false);
    setSubscription_list(list);
  };

  // on Edit Subscription
  const onEditSubscription = subscription => {
    setSelectedSubscription(subscription);
    setIsEditSubscriptionSidepanOpen(true);
  };

  return (
    <div className=" w-full     px-8  pb-10 mt-2 flex flex-col   max-h-[90%] overflow-y-auto">
      {/* Page header */}
      <NavigationHeader className=" flex items-center justify-between">
        <div className="flex items-center gap-2 ">
          <button
            className=" text-primary rounded-full p-3 bg-[#F2F6F6]
          "
            onClick={() => navigate(-1)}>
            {ICONS.left_arrow}
          </button>
          <h2 className=" text-xl sm:text-3xl font-bold text-black">
            Subscriptions
          </h2>
        </div>
        <div className="flex item justify-end ">
          <SecondryButton
            btnAction={() => {
              setIsOpenConfigSidepan(true);
            }}
            showSaveBtn={true}
            btnTitle={'Config'}
          />
          <SaveButton
            saveBtn={() => {
              setIsOpenSubscriptionSidepan(true);
            }}
            showSaveBtn={true}
            saveBtnTitle={'Add Subscription'}
          />
        </div>
      </NavigationHeader>

      {/* List */}
      <SubscriptionList
        subscription_list={subscription_list}
        setIsLoading={setIsLoading}
        load_subscription_list={load_subscription_list}
        onEditSubscription={onEditSubscription}
      />

      {/*  modal for configs */}
      {isOpenConfigSidepan && (
        <Modal onCloseModal={() => setIsOpenConfigSidepan(false)}>
          <div className=" max-w-[700px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 px-4 py-2">
            <Subscription onCloseModal={() => setIsOpenConfigSidepan(false)} />
          </div>
        </Modal>
      )}

      {/* Add Subscription Modal */}
      {isOpenSubscriptionSidepan && (
        <Modal onCloseModal={() => setIsOpenSubscriptionSidepan(false)}>
          <div className=" max-w-[700px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 px-4 py-2">
            <AddSubscriptionListing
              onCloseModal={() => setIsOpenSubscriptionSidepan(false)}
              load_subscription_list={load_subscription_list}
            />
          </div>
        </Modal>
      )}
      {/* Edit  Subscription Modal */}
      {isEditSubscriptionSidepanOpen && (
        <Modal
          onCloseModal={() => {
            setIsEditSubscriptionSidepanOpen(false);
            setSelectedSubscription(null);
          }}>
          <div className=" max-w-[700px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 px-4 py-2">
            <EditSubscriptionListing
              onCloseModal={() => {
                setIsEditSubscriptionSidepanOpen(false);
                setSelectedSubscription(null);
              }}
              load_subscription_list={load_subscription_list}
              selected_listing={selected_subscription}
            />
          </div>
        </Modal>
      )}

      {/*  */}
      <ToastContainer />
      {/*  */}
      <AppLoader enable={!isLoading} />
    </div>
  );
};

export default SubscriptionLayout;
