/* eslint-disable react/react-in-jsx-scope */
import account_Icon from '../assets/accountIcon.png';
import adBanner_Icon from '../assets/adBannerIcon.png';
import listing_Icon from '../assets/listingIcon.png';
import collectionsIcon from '../assets/collections.png';
import revenue_Icon from '../assets/revenueIcon.png';
import settingIcon from '../assets/settingIcon.png';
import transactionIcon from '../assets/transaction.png';
import usersIcon from '../assets/users.png';
import orderIcon from '../assets/orderIcon.png';
import mediaIcon from '../assets/mediaIcon.png';
import reviewIcon from '../assets/reviewIcon.png';
import reportIcon from '../assets/reportIcon.png';
import channelIcon from '../assets/channelIcon.png';
import moreMenuIcon from '../assets/moreMenuIcon.png';
import webIcon from '../assets/webIcon.png';
import appIcon from '../assets/appIcon.png';
import nativeintegrationIcon from '../assets/nativeintegration.png';
import mobileIcon from '../assets/mobileConfigIcon.png';
import appTranslateIcon from '../assets/appTranslate.png';
import seoIcon from '../assets/seoIcon.png';
import iconGlobal from '../assets/global.png';
import moreIcon from '../assets/moreIcon.png';
import openLinkIcon from '../assets/whiteOpenLinkIcon.png';
import communicationIcon from '../assets/communicationIcon.png';
import deliveryIcon from '../assets/deliveryIcon.png';
import marketplaceIcon from '../assets/marketplaceIcon.png';
import inviteTeamIcon from '../assets/inviteTeamIcon.png';

import branchesIcon from '../assets/branchesIcon.png';
import auditIcon from '../assets/auditIcon.png';
import driver from '../assets/driver.png';
import fleetsIcon from '../assets/fleetsIcon.png';
import rosterIcon from '../assets/rosterIcon.png';
import starIcon from '../assets/starIcon.png';
import home_Icon from '../assets/homeIcon.png'; // Tell webpack this JS file uses this image
import multiCurrencyIcon from './../assets/multiCurrency.png';
import currencyIcon from './../assets/currency.png';
import arrowFromLeft from './../assets/arrow-from-left.png';
import arrowFromRight from './../assets/arrow-from-right.png';
import closeIcon from './../assets/closeIcon.png';
import reportedIcon from './../assets/GroupReport.png';
import help_Icon from '../assets/helpIcon.png';
import documentationIcon from '../assets/documentationIcon.png';
import apiRefIcon from '../assets/apiRefIcon.png';
import communityIcon from '../assets/communityIcon.png';
import menu_Icon from '../assets/menuIcon.png';
import closIcon from '../assets/closeBlackIcon.png';
import notiIcon from '../assets/notificationIcon.png';
import aacountsIcon from '../assets/accounts.png';
import listingsIcon from '../assets/listings.png';
import shippingMethodIcon from '../assets/shippingMethod.png';
import paymentMethodIcon from './../assets/paymentMethod.png';
import commissionsIcon from '../assets/commissions.png';
import subscriptionsIcon from '../assets/subscriptions.png';
import specialFeesIcon from '../assets/specialFees.png';
import languagecon from '../assets/language.png';
import apiIcon from '../assets/api.png';

export var ICONS = {
  home_Icon,
  account_Icon,
  adBanner_Icon,
  listing_Icon,
  collectionsIcon,
  revenue_Icon,
  settingIcon,
  transactionIcon,
  usersIcon,
  orderIcon,
  mediaIcon,
  reviewIcon,
  reportIcon,
  channelIcon,
  moreMenuIcon,
  webIcon,
  appIcon,
  nativeintegrationIcon,
  mobileIcon,
  appTranslateIcon,
  seoIcon,
  iconGlobal,
  moreIcon,
  openLinkIcon,
  communicationIcon,
  deliveryIcon,
  marketplaceIcon,
  branchesIcon,
  auditIcon,
  driver,
  fleetsIcon,
  rosterIcon,
  starIcon,
  multiCurrencyIcon,
  currencyIcon,
  arrowFromLeft,
  arrowFromRight,
  closeIcon,
  reportedIcon,
  apiRefIcon,
  communityIcon,
  documentationIcon,
  help_Icon,
  inviteTeamIcon,
  menu_Icon,
  closIcon,
  notiIcon,
  aacountsIcon,
  listingsIcon,
  shippingMethodIcon,
  paymentMethodIcon,
  commissionsIcon,
  subscriptionsIcon,
  specialFeesIcon,
  languagecon,
  apiIcon,
  tradly_logo: (
    <svg
      width="50"
      height="50"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 0C97.7939 0 126 28.2061 126 63C126 97.7939 97.7939 126 63 126C28.2061 126 0 97.7939 0 63C0 28.2061 28.2061 0 63 0Z"
        fill="url(#paint0_linear_1500_61)"
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 119C93.9279 119 119 93.9279 119 63C119 32.0721 93.9279 7 63 7C32.0721 7 7 32.0721 7 63C7 93.9279 32.0721 119 63 119Z"
        stroke="white"
        stroke-width="1.4"
      />
      <g opacity="0.5" filter="url(#filter0_d_1500_61)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M63 105C86.196 105 105 86.4199 105 63.5C105 40.5801 86.196 22 63 22C39.804 22 21 40.5801 21 63.5C21 86.4199 39.804 105 63 105Z"
          stroke="white"
          stroke-width="1.4"
          shape-rendering="crispEdges"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.9842 7C48.329 7 34.1435 13.3548 23.8912 23.6071C18.6743 28.824 14.4654 35.0586 11.5892 41.9949C9.96038 45.9231 11.8244 50.4281 15.7527 52.057C19.681 53.6858 24.1859 51.8218 25.8148 47.8935C27.9054 42.8516 30.9708 38.3064 34.7806 34.4965C40.5365 28.7406 47.9684 24.682 56.2842 23.1155V111.922C56.2842 116.174 59.7316 119.622 63.9842 119.622C68.2368 119.622 71.6842 116.174 71.6842 111.922V23.1155C80 24.682 87.4318 28.7407 93.1877 34.4965L93.1879 34.4968C96.998 38.3065 100.063 42.8515 102.153 47.8931C103.782 51.8215 108.287 53.6858 112.215 52.0571C116.143 50.4285 118.008 45.9236 116.379 41.9953C113.503 35.0588 109.295 28.8241 104.077 23.6071C93.8262 13.3562 79.6431 7.00169 63.9905 7C63.9884 7 63.9863 7 63.9842 7Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_1500_61"
          x="16.3"
          y="21.3"
          width="93.4001"
          height="92.3999"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1500_61"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1500_61"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1500_61"
          x1="0"
          y1="0"
          x2="126"
          y2="126"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#ACF684" />
          <stop offset="0.338542" stop-color="#83EDA2" />
          <stop offset="0.682292" stop-color="#3AD87E" />
          <stop offset="1" stop-color="#13B58C" />
        </linearGradient>
      </defs>
    </svg>
  ),
  search_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
      />
    </svg>
  ),
  external_link: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-4 h-4">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  ),
  left_arrow: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9323 7.30009H3.11311L8.40471 2.20696C8.68179 1.94026 8.69253 1.49716 8.4286 1.21717C8.16503 0.937521 7.72654 0.926321 7.44911 1.19302L1.40593 7.00995C1.14443 7.27454 1 7.62594 1 8.00009C1 8.37388 1.14443 8.72563 1.41805 9.00177L7.44946 14.8068C7.5835 14.936 7.75529 15 7.92708 15C8.10996 15 8.29283 14.9272 8.42895 14.7827C8.69287 14.5027 8.68214 14.0599 8.40505 13.7932L3.09129 8.70008H16.9323C17.3147 8.70008 17.625 8.38648 17.625 8.00009C17.625 7.61369 17.3147 7.30009 16.9323 7.30009Z"
        fill="#01544E"
        stroke="#01544E"
        stroke-width="0.3"
      />
    </svg>
  ),
  eye: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  ),
  eye_close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  ),
  copy: (
    <svg
      width="20"
      height="22"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 18H5.5C4.70435 18 3.94129 17.6839 3.37868 17.1213C2.81607 16.5587 2.5 15.7956 2.5 15V5C2.5 4.73478 2.39464 4.48043 2.20711 4.29289C2.01957 4.10536 1.76522 4 1.5 4C1.23478 4 0.98043 4.10536 0.792893 4.29289C0.605357 4.48043 0.5 4.73478 0.5 5V15C0.5 16.3261 1.02678 17.5979 1.96447 18.5355C2.90215 19.4732 4.17392 20 5.5 20H13.5C13.7652 20 14.0196 19.8946 14.2071 19.7071C14.3946 19.5196 14.5 19.2652 14.5 19C14.5 18.7348 14.3946 18.4804 14.2071 18.2929C14.0196 18.1054 13.7652 18 13.5 18ZM18.5 6.94C18.4896 6.84813 18.4695 6.75763 18.44 6.67V6.58C18.3919 6.47718 18.3278 6.38267 18.25 6.3L12.25 0.3C12.1673 0.222216 12.0728 0.158081 11.97 0.11H11.88L11.56 0H7.5C6.70435 0 5.94129 0.316071 5.37868 0.87868C4.81607 1.44129 4.5 2.20435 4.5 3V13C4.5 13.7956 4.81607 14.5587 5.37868 15.1213C5.94129 15.6839 6.70435 16 7.5 16H15.5C16.2956 16 17.0587 15.6839 17.6213 15.1213C18.1839 14.5587 18.5 13.7956 18.5 13V7C18.5 7 18.5 7 18.5 6.94ZM12.5 3.41L15.09 6H13.5C13.2348 6 12.9804 5.89464 12.7929 5.70711C12.6054 5.51957 12.5 5.26522 12.5 5V3.41ZM16.5 13C16.5 13.2652 16.3946 13.5196 16.2071 13.7071C16.0196 13.8946 15.7652 14 15.5 14H7.5C7.23478 14 6.98043 13.8946 6.79289 13.7071C6.60536 13.5196 6.5 13.2652 6.5 13V3C6.5 2.73478 6.60536 2.48043 6.79289 2.29289C6.98043 2.10536 7.23478 2 7.5 2H10.5V5C10.5 5.79565 10.8161 6.55871 11.3787 7.12132C11.9413 7.68393 12.7044 8 13.5 8H16.5V13Z"
        fill="currentColor"
      />
    </svg>
  ),
  plus: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  ),
  minus: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
    </svg>
  ),
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  ),
  hand_burgur: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
    </svg>
  ),
  trash_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-4 h-4">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
      />
    </svg>
  ),
  camera_icon: (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75033 0.166672L5.76783 2.33334H2.33366C1.14199 2.33334 0.166992 3.30834 0.166992 4.50001V17.5C0.166992 18.6917 1.14199 19.6667 2.33366 19.6667H19.667C20.8587 19.6667 21.8337 18.6917 21.8337 17.5V4.50001C21.8337 3.30834 20.8587 2.33334 19.667 2.33334H16.2328L14.2503 0.166672H7.75033ZM11.0003 16.4167C8.01033 16.4167 5.58366 13.99 5.58366 11C5.58366 8.01001 8.01033 5.58334 11.0003 5.58334C13.9903 5.58334 16.417 8.01001 16.417 11C16.417 13.99 13.9903 16.4167 11.0003 16.4167Z"
        fill="white"
      />
    </svg>
  ),
  close_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  ),
  delete_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
      />
    </svg>
  ),
  edit_icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6   "
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
      />
    </svg>
  ),
  youtube_icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 9.71C23.0495 8.27865 22.7365 6.85802 22.09 5.58C21.6514 5.0556 21.0427 4.70171 20.37 4.58C17.5875 4.32752 14.7936 4.22404 12 4.27C9.21667 4.22196 6.43274 4.3221 3.66003 4.57C3.11185 4.66972 2.60454 4.92684 2.20003 5.31C1.30003 6.14 1.20003 7.56 1.10003 8.76C0.954939 10.9176 0.954939 13.0824 1.10003 15.24C1.12896 15.9154 1.22952 16.5858 1.40003 17.24C1.5206 17.7451 1.76455 18.2123 2.11003 18.6C2.51729 19.0035 3.03641 19.2752 3.60003 19.38C5.75594 19.6461 7.92824 19.7564 10.1 19.71C13.6 19.76 16.67 19.71 20.3 19.43C20.8775 19.3316 21.4112 19.0595 21.83 18.65C22.11 18.3699 22.3191 18.0271 22.44 17.65C22.7977 16.5526 22.9733 15.4041 22.96 14.25C23 13.69 23 10.31 23 9.71ZM9.74003 14.85V8.66L15.66 11.77C14 12.69 11.81 13.73 9.74003 14.85Z"
        fill="url(#paint0_linear_3700_5604)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3700_5604"
          x1="23.0051"
          y1="19.7271"
          x2="23.0051"
          y2="4.25832"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#14B78E" />
          <stop offset="1" stop-color="#2ADABF" />
        </linearGradient>
      </defs>
    </svg>
  ),
  dollar_icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 11H13V7H15C15.2652 7 15.5196 7.10536 15.7071 7.29289C15.8946 7.48043 16 7.73478 16 8C16 8.26522 16.1054 8.51957 16.2929 8.70711C16.4804 8.89464 16.7348 9 17 9C17.2652 9 17.5196 8.89464 17.7071 8.70711C17.8946 8.51957 18 8.26522 18 8C18 7.20435 17.6839 6.44129 17.1213 5.87868C16.5587 5.31607 15.7956 5 15 5H13V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5H10C8.93913 5 7.92172 5.42143 7.17157 6.17157C6.42143 6.92172 6 7.93913 6 9C6 10.0609 6.42143 11.0783 7.17157 11.8284C7.92172 12.5786 8.93913 13 10 13H11V17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19H11V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19H14C15.0609 19 16.0783 18.5786 16.8284 17.8284C17.5786 17.0783 18 16.0609 18 15C18 13.9391 17.5786 12.9217 16.8284 12.1716C16.0783 11.4214 15.0609 11 14 11V11ZM11 11H10C9.46957 11 8.96086 10.7893 8.58579 10.4142C8.21071 10.0391 8 9.53043 8 9C8 8.46957 8.21071 7.96086 8.58579 7.58579C8.96086 7.21071 9.46957 7 10 7H11V11ZM14 17H13V13H14C14.5304 13 15.0391 13.2107 15.4142 13.5858C15.7893 13.9609 16 14.4696 16 15C16 15.5304 15.7893 16.0391 15.4142 16.4142C15.0391 16.7893 14.5304 17 14 17Z"
        fill="url(#paint0_linear_3700_5601)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3700_5601"
          x1="18"
          y1="22"
          x2="18"
          y2="2"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#14B78E" />
          <stop offset="1" stop-color="#2ADABF" />
        </linearGradient>
      </defs>
    </svg>
  ),
};
