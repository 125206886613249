import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  SafeAreaView,
  Platform,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from '../HelperClasses/AppToast';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import commonStyleSheet from '../StyleSheets/CommonStyleSheet';
import Moment from 'moment';
import AppConstants from '../Constants/AppConstants';
import Switch from 'react-switch';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {addMixpanelEvent, MixPannelEnum} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import {capitalizeFirstLetter} from '../HelperClasses/SingletonClass';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import DatePicker from 'react-datepicker';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let dateFormat = 'yyyy-MM-DD hh:mm a';

var dt = new Date();
let currentDate = Moment(dt).format(dateFormat);

let MediumTypesArray = ['Flat', 'Percentage'];
var pageNo = 1;

export default class AddCoupon extends Component {
  constructor(props) {
    super(props);
    const promoBData = this.props.promoBannerData;
    const isEdit = this.props.isEdit;
    this.state = {
      showDropDown: false,
      status: true,
      dataLoad: true,
      name: '',
      valueID: '',
      id: '',
      updateUI: false,
      note: 'Great! You have got {amount} discount.',
      code: '',
      discountType: MediumTypesArray[0],
      discountValue: '',
      minAmount: '0',
      maxAmount: '0',
      maxDiscount: '0',
      maxUses: '0',
      maxUsesUser: '0',
      newUser: false,
      selectedStartDate: '',
      selectedEndDate: '',
      shortCode: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    couponsData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    const couponData = this.props.couponsData;
    const isEdit = this.props.isEdit;
    // console.log('isEdit =>', isEdit);
    if (isEdit) {
      let sdtm = Moment(couponData['start_at']).utc(false);
      let edtm = Moment(couponData['end_at']).utc(false);
      if (this.state.name.length == 0) {
        this.setState({
          name: couponData['reference_name'],
          status: couponData['active'],
          id: couponData['id'],
          code: couponData['code'],
          note: couponData['note'],
          discountType: capitalizeFirstLetter(couponData['discount_type']),
          discountValue: couponData['discount_value'],
          minAmount: couponData['min_amount'],
          maxAmount: couponData['max_amount'],
          maxDiscount: couponData['max_discount'],
          maxUses: couponData['max_uses'],
          maxUsesUser: couponData['max_uses_user'],
          newUser: couponData['new_user'],
          shortCode: couponData['short_code'],
          selectedStartDate: new Date(sdtm),
          selectedEndDate: new Date(edtm),
        });
      }
    }
  }

  addcouponApi = async image => {
    let mediumString = this.state.discountType.toLowerCase();
    let startutcDate = Moment(this.state.selectedStartDate).utc(true);
    let endutcDate = Moment(this.state.selectedEndDate).utc(true);
    var dict = {
      reference_name: this.state.name ?? '',
      code: this.state.code ?? '',
      note: this.state.note ?? '',
      coupon_type: 'direct_discount',
      discount_type: mediumString,
      discount_value: this.state.discountValue ?? '',
      min_amount: this.state.minAmount ?? '',
      max_amount: this.state.maxAmount ?? '',
      max_discount: this.state.maxDiscount ?? '',
      max_uses: this.state.maxUses ?? '',
      max_uses_user: this.state.maxUsesUser ?? '',
      new_user: this.state.newUser ?? '',
      start_at: startutcDate ?? '',
      end_at: endutcDate ?? '',
      short_code: this.state.shortCode ?? '',
      active: this.state.status,
    };
    var path = APPURL.URLPaths.coupons;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.coupons + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'PATCH' : 'POST',
      JSON.stringify({coupon: dict}),
    );
    console.log('coupons', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.props.backBtnAction(true);
      AppToast();
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event =
      this.state.id !== '' ? MixPannelEnum.editCoupon : MixPannelEnum.addCoupon;
    addMixpanelEvent(event, properties);
  }
  //MARK:-  Button Actions
  submitButtonAction = () => {
    this.setState({dataLoad: false});
    this.addcouponApi();
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
  };

  didSelectDropDown = item => {
    this.state.discountType = item;
    this.setState({updateUI: !this.state.updateUI});
  };
  handleCodeText(text) {
    if (text.length < 192) {
      this.state.code = text.toUpperCase();
      this.setState({updateUI: !this.state.updateUI});
    }
  }
  amountFieldsOnChange(value, id) {
    let reg = id == 5 || id == 6 ? /^[0-9-\b]+$/ : /^[0-9-.\b]+$/;
    var txtValue = '';
    if (value == '' || reg.test(value)) {
      txtValue = value;
      if (id == 1) {
        this.state.discountValue = txtValue;
      } else if (id == 2) {
        this.state.minAmount = txtValue;
      } else if (id == 3) {
        this.state.maxAmount = txtValue;
      } else if (id == 4) {
        this.state.maxDiscount = txtValue;
      } else if (id == 5) {
        this.state.maxUses = txtValue;
      } else if (id == 6) {
        this.state.maxUsesUser = txtValue;
      }
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  onChangeShortCode = text => {
    let value = text;
    let reg = /^[a-zA-Z0-9\s]+$/;
    if (text == '' || reg.test(value)) {
      if (value.length < 7) {
        this.setState({shortCode: text.toUpperCase()});
      }
    }
  };
  // UI Renders
  renderTypeView = () => {
    const typesArray = MediumTypesArray;
    var view = [];
    for (let a = 0; a < 2; a++) {
      let item = typesArray[a];
      let check = this.state.discountType == item ? true : false;
      view.push(
        <TouchableOpacity
          style={check ? styles.selecedTypesViewStyle : styles.typesViewStyle}
          onPress={id => this.didSelectDropDown(item)}>
          <Text style={styles.titleStyle}>{`${item} ${
            a == 0 ? '(Ex: $10)' : '(Ex: 10%)'
          }`}</Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.typesViewContainerStyle}> {view}</View>;
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderDatePickerView = () => {
    let strtD =
      this.state.selectedStartDate.length == 0
        ? 'Select start date time'
        : Moment(this.state.selectedStartDate).format(dateFormat);
    return (
      <View
        style={[commonStyleSheet.subViewStyle, {zIndex: 12185, marginTop: 10}]}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <this.titleLblRender title={'Start Date Time'} />
        </View>
        <View
          style={[
            tableStyle.dateTextField,
            {
              height: 40,
              marginRight: 0,
              marginLeft: itsMobileView ? 0 : 10,
              marginTop: 0,
              width: itsMobileView ? '100%' : '80%',
            },
          ]}>
          <DatePicker
            selected={this.state.selectedStartDate}
            onChange={date => this.setState({selectedStartDate: date})}
            dateFormat="MM/dd/yyyy h:mm:ss"
            showTimeInput={true}
            customInput={
              <View style={{width: 240, alignItems: 'center'}}>
                <Text
                  style={[
                    appConfigStyle.textFieldAddCurrencyStyle,
                    styles.dateFieldStyle,
                  ]}>
                  {strtD}
                </Text>
              </View>
            }
          />
        </View>
      </View>
    );
  };
  renderEndDatePickerView = () => {
    let strtD =
      this.state.selectedEndDate.length == 0
        ? 'Select end date time'
        : Moment(this.state.selectedEndDate).format(dateFormat);
    return (
      <View style={[commonStyleSheet.subViewStyle, {zIndex: 2185}]}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <this.titleLblRender title={'End Date Time'} />
        </View>
        <View
          style={[
            tableStyle.dateTextField,
            {
              height: 40,
              marginRight: 0,
              marginLeft: itsMobileView ? 0 : 0,
              marginTop: 0,
              width: itsMobileView ? '100%' : '80%',
            },
          ]}>
          <DatePicker
            selected={this.state.selectedEndDate}
            onChange={date => this.setState({selectedEndDate: date})}
            dateFormat="MM/dd/yyyy h:mm:ss"
            showTimeInput={true}
            customInput={
              <View style={{width: 240, alignItems: 'center'}}>
                <Text
                  style={[
                    appConfigStyle.textFieldAddCurrencyStyle,
                    styles.dateFieldStyle,
                  ]}>
                  {strtD}
                </Text>
              </View>
            }
          />
        </View>
      </View>
    );
  };
  render() {
    let isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={isEdit ? 'Edit Coupon' : 'Add Coupon'}
          backBtn={this.props.backBtnAction}
          saveBtnTitle={isEdit ? 'Update' : 'Create'}
          saveBtn={() => this.submitButtonAction()}
        />
        <View style={tableStyle.subContainerView}>
          <ScrollView>
            <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
              <View style={commonStyleSheet.promoMainView}>
                <View style={{height: 20}} />
                <View style={commonStyleSheet.subViewStyle}>
                  <this.titleLblRender title={'Reference Name'} />
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.name}
                    placeholder="Enter name"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({name: name})}
                  />
                </View>
                <View style={styles.dropDownView}>
                  <Text style={commonStyleSheet.titleNameStyle}>
                    {'Coupon type'}
                  </Text>
                  <View style={{width: '80%'}}>
                    <this.renderTypeView />
                  </View>
                </View>
                <View style={{height: 10}} />
                <View style={commonStyleSheet.subViewStyle}>
                  <this.titleLblRender title={'Discount Value'} />
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.discountValue}
                    placeholder="Enter discount value"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.amountFieldsOnChange(val, 1)}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={commonStyleSheet.subViewStyle}>
                  <this.titleLblRender title={'Code'} />
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.code}
                    placeholder="Ex: HOLIDAY10"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={code => this.handleCodeText(code)}
                  />
                </View>
                <this.renderDatePickerView />
                <this.renderEndDatePickerView />
                <View style={commonStyleSheet.switchViewStyle}>
                  <Text style={commonStyleSheet.titleNameStyle}>Status</Text>
                  <View
                    style={{
                      width: itsMobileView ? '12%' : '80%',
                      flexDirection: 'row',
                    }}>
                    <Switch
                      onColor={colors.AppNewGreen}
                      onChange={this.statusToggle}
                      checked={this.state.status}
                      height={22}
                      width={50}
                    />
                  </View>
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 182}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <this.titleLblRender title={'Short Code'} />
                    <ToolTipViewBtn
                      title={AppConstantsMessage.shortCodeTitleToopTipMsg}
                      description={AppConstantsMessage.shortCodeSubTitleToolTipMsg.replace(
                        'commission',
                        'coupon',
                      )}
                    />
                  </View>
                  <TextInput
                    editable={isEdit ? false : true}
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.shortCode}
                    placeholder="Enter short code"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.onChangeShortCode(val)}
                  />
                </View>
                <Text style={tableStyle.mainTitile}>ADVANCED</Text>
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 129}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'Note'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={AppConstantsMessage.couponNoteMsg}
                    />
                  </View>
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.note}
                    placeholder="Enter note"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={note => this.setState({note: note})}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 101}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'Minimum Amount'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={AppConstantsMessage.couponMinimumAmountMsg}
                    />
                  </View>
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.minAmount}
                    placeholder="Enter minimum amount"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.amountFieldsOnChange(val, 2)}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 98}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'Maximum Amount'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={AppConstantsMessage.couponMaximumAmountMsg}
                    />
                  </View>
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.maxAmount}
                    placeholder="Enter maximum amount"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.amountFieldsOnChange(val, 3)}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 95}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'Maximum Discount Amount'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={
                        AppConstantsMessage.couponMaximumDiscountAmountMsg
                      }
                    />
                  </View>
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.maxDiscount}
                    placeholder="Enter maximum discount amount"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.amountFieldsOnChange(val, 4)}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 92}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'Maximum Uses'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={AppConstantsMessage.couponMaximumUsesMsg}
                    />
                  </View>
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.maxUses}
                    placeholder="Enter maximum uses"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.amountFieldsOnChange(val, 5)}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 90}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'Maximum Uses Per User'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={
                        AppConstantsMessage.couponMaximumUsesPerUserMsg
                      }
                    />
                  </View>
                  <TextInput
                    style={[
                      appConfigStyle.textFieldAddCurrencyStyle,
                      {outline: 0},
                    ]}
                    value={this.state.maxUsesUser}
                    placeholder="Enter maximum uses per user"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={val => this.amountFieldsOnChange(val, 6)}
                  />
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 88}]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 9829,
                    }}>
                    <Text style={commonStyleSheet.titleNameStyle}>
                      {'New User'}
                    </Text>
                    <ToolTipViewBtn
                      title={''}
                      description={AppConstantsMessage.couponNewUserMsg}
                    />
                  </View>
                  <View
                    style={{
                      width: itsMobileView ? '12%' : '80%',
                      flexDirection: 'row',
                    }}>
                    <Switch
                      onColor={colors.AppNewGreen}
                      onChange={() =>
                        this.setState({newUser: !this.state.newUser})
                      }
                      checked={this.state.newUser}
                      height={22}
                      width={50}
                    />
                  </View>
                </View>

                <View style={{height: 40}} />
              </View>
            </View>
            <View style={{height: 50}} />
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    height: windowHeight,
    backgroundColor: colors.AppLightGreen,
  },
  dropDownView: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 11,
  },
  dropDownFieldStyle: {
    marginLeft: 5,
    width: itsMobileView ? '85%' : '96%',
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropDownContainerStyle: {
    paddingLeft: 5,
    margin: 5,
    marginLeft: itsMobileView ? 0 : 10,
    width: itsMobileView ? '100%' : '80%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    flexDirection: 'row',
    zIndex: 12,
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    marginTop: 38,
    marginLeft: -5,
    zIndex: 9000,
  },
  datepicker1ViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 9,
  },
  datepicker2ViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 8,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    padding: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    padding: 10,
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    backgroundColor: 'white',
    width: windowWidth / 2,
    marginLeft: -15,
  },
  dateFieldStyle: {
    borderWidth: 0,
    margin: 0,
    paddingLeft: itsMobileView ? 5 : -30,
    marginLeft: itsMobileView ? 5 : -30,
    marginTop: 5,
  },
});
