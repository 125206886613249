import React, {useState} from 'react';
import SubMenu from './SubMenu';
import {useNavigate, useLocation} from 'react-router-dom';

const SideMenu = ({menu, isExpand}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const windowHeight = window?.innerHeight;

  // states
  const [current_menu_position, setCurrentMenuPosition] = useState(null);

  const menu_click = menu => {
    if (menu.href && (!menu?.sub_menus || menu?.sub_menus?.length == 0)) {
      menu?.isOpenNewTab
        ? window.open(menu.href, '_blank')
        : navigate(menu.href);
    }
  };

  // class
  const hover_button_styles =
    'bg-transparent group-hover:bg-lightYellow group-hover:before:h-full  group-hover:before:w-1.5 group-hover:before:bg-AppYellow  group-hover:before:absolute group-hover:before:left-0 transition-all duration-200';
  const active_button_styles =
    '!bg-lightYellow before:h-full  before:w-1.5 before:bg-AppYellow before:absolute before:left-0  ';

  // current path check

  const isCurrentPathActive = ({main_menu, sub_menu}) => {
    let all_path = [main_menu.href];
    if (sub_menu && sub_menu?.length > 0) {
      all_path = [...all_path, ...sub_menu.map(m => m.href)];
    }

    return all_path.includes(location.pathname);
  };

  //
  const onMouseEnter = event => {
    setCurrentMenuPosition({
      X: event.nativeEvent.pageX,
      Y: event.nativeEvent.pageY,
    });
  };
  //
  const onMouseOut = event => {
    setCurrentMenuPosition(null);
  };

  //

  return (
    <div
      className={`group  relative flex items-start justify-start  ${
        windowHeight - current_menu_position?.Y < 400
          ? 'items-end'
          : 'items-start'
      }`}
      onClick={() => menu_click(menu)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseOut}>
      <div
        className={` w-full relative flex items-center justify-start text-white gap-4 py-2 px-4 cursor-pointer ${hover_button_styles}  ${
          isCurrentPathActive({main_menu: menu, sub_menu: menu?.sub_menus}) &&
          active_button_styles
        }`}>
        <img className="w-5 h-5 object-contain" src={menu.icon} alt="" />
        <p
          className={`${
            isExpand ? 'opacity-100 block text-sm' : 'opacity-0 hidden '
          }`}>
          {menu.title}
        </p>
      </div>

      {menu?.sub_menus?.length > 0 && (
        <div
          className={` hidden  group-hover:block   fixed    z-50 ${
            isExpand ? 'left-[95px] pl-[125px]' : 'left-14 px-2'
          } `}>
          <SubMenu main_menu={menu} sub_menu={menu?.sub_menus} />
        </div>
      )}
    </div>
  );
};

export default SideMenu;
