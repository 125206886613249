import React, {useRef, useState} from 'react';
import {ShipmentStatus} from '../../../Constants/Status';
import networkService from '../../../Network/NetworkManager';
import {AppToast} from '../../../HelperClasses/AppToast';
import AppLoader from '../../../HelperClasses/AppLoaderClasses/AppLoader';
const APPURL = require('../../../Constants/URLConstants');

const ShipmentOrderStatus = ({order_details, shipment, load_order_details}) => {
  const selectRef = useRef(null);
  const [isLoading, setISLoading] = useState(false);

  //Status change function
  const status_change = async (status, order_details) => {
    if (status) {
      setISLoading(true);
      if (Number(status) !== 4) {
        const update_data = {
          shipment: {
            status: Number(status),
          },
        };

        const responseJson = await networkService.networkCall(
          APPURL.URLPaths.order +
            `${order_details?.id}/shipments/${shipment?.id}/status`,
          'PATCH',
          JSON.stringify(update_data),
        );
        if (responseJson?.status) {
          setISLoading(false);

          AppToast(undefined, '.Status changed successfully');
          load_order_details();
        } else {
          setISLoading(false);

          AppToast(responseJson);
        }
      }
      // else {
      //   if (Object.keys(shipment?.pickup_address).length === 0) {
      //     setShowError(true);
      //     setError_message(
      //       Address_text.address.alert_message_add_pickup_address
      //     );
      //   } else {
      //     dispatch(
      //       changeShipmentStatus({
      //         id: order_details.id,
      //         shipment_id: shipment.id,
      //         sendData: {
      //           shipment: {
      //             status: Number(status),
      //           },
      //         },
      //       })
      //     ).then((res) => {
      //       if (!res.payload.code) {
      //         setShowSuccess(true);
      //         setSuccess_message(
      //           Orders_text.orderdetail.status_changed_successfully
      //         );
      //         dispatch(
      //           get_order_details({
      //             authKey: auth_key,
      //             id: order_details.id,
      //             bodyParam: { account_id: router.query.account_id },
      //           })
      //         );
      //         handleResetClick();
      //       } else {
      //         dispatch(
      //           get_order_details({
      //             authKey: auth_key,
      //             id: order_details.id,
      //             bodyParam: { account_id: router.query.account_id },
      //           })
      //         );
      //         if (isChangeStatusError) {
      //           setShowError(true);
      //           setError_message(changeStatusErrorMessage);
      //         }
      //         handleResetClick();
      //       }
      //     });
      //   }
      // }
    }
  };

  return (
    <>
      {/* {isChangeStatusFetching && <CustomLoading />} */}
      <AppLoader enable={isLoading ? false : true} />

      {shipment?.next_status.length > 0 && (
        <div className="flex   justify-end">
          <select
            id="all_next_status"
            ref={selectRef}
            className="
                    block
                      max-w-[254px] w-full
                    rounded-button
                    border-primary
                     text-primary
                  "
            onChange={e => {
              if (e.target.value !== '') {
                status_change(e.target.value, order_details);
              }
            }}>
            <option selected value="">
              Change shipment status
            </option>
            {shipment?.next_status.length > 0 ? (
              shipment?.next_status.map(status => {
                return (
                  <>
                    <option key={status} value={status}>
                      {ShipmentStatus(status)}
                    </option>
                  </>
                );
              })
            ) : (
              <option disabled>Not available</option>
            )}
          </select>
        </div>
      )}
    </>
  );
};

export default ShipmentOrderStatus;
