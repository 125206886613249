'use strict';
import AppConstants from '../Constants/AppConstants';
import colors from '../HelperClasses/AppColor';
import {
  b1,
  b2,
  button1,
  button2,
  h1,
  h2,
  h3,
  t1,
  t2,
} from './TextTitleStyleSheet';
var React = require('react-native');
var {StyleSheet, Dimensions, I18nManager} = React;
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
const alignment = I18nManager.isRTL ? 'right' : 'left';
const direction = I18nManager.isRTL ? 'rtl' : 'ltr';
const rotationDeg = I18nManager.isRTL ? '180deg' : '0deg';

export default StyleSheet.create({
  welcomeTitleStyle: {
    // ...h2,
    fontSize: 24,
    fontWeight: '400',
    textAlign: 'center',
    marginTop: 16,
  },
  welcomeSubTitleTextStyle: {
    // ...h3,
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center',
    marginTop: 16,
  },
  teanantNameStyle: {
    // ...h3,
    fontSize: 20,
    fontWeight: '600',
    color: colors.AppNewGreen,
  },
  headerTitleStyle: {
    // ...h1,
    fontSize: 36,
    fontWeight: 'bold',
    color: colors.AppNewGreen,
    paddingLeft: 20,
    paddingRight: 20,
  },
  simpleParagraphStyle: {
    ...t1,
    color: colors.Appgray,
  },
  themeColorTextStyle: {
    ...t2,
    color: colors.AppNewGreen,
    padding: 5,
  },
  plainTextStyle: {
    ...t1,
    color: colors.AppTitleBlack,
  },
  plainSubTextStyle: {
    ...t2,
    color: colors.AppTitleBlack,
  },
  helperTextStyle: {
    ...b2,
    color: colors.Appgray,
  },
  onBoardingStyle: {
    ...t2,
    color: colors.Appgray,
  },
  applyNxtBtnStyle: {
    ...b1,
    color: colors.AppWhite,
    textAlign: 'center',
  },
  uploadHereTextStyle: {
    textAlign: 'center',
    color: colors.Appgray,
    marginTop: 16,
    ...button1,
  },
  testModeTextStyle: {
    ...t2,
    alignSelf: 'center',
    marginRight: 5,
  },
  environmentTextStyle: {
    ...t2,
    marginTop: 0,
    alignSelf: 'flex-end',
    color: 'white',
    height: 20,
    marginRight: 0,
    backgroundColor: colors.orangeColor,
  },
  inviteMemberTitleStyle: {
    ...t2,
    alignSelf: 'center',
    marginTop: 20,
  },
  inviteMemberSubTitleStyle: {
    ...b1,
    alignSelf: 'center',
    color: colors.Appgray,
  },
  emailTextFieldStyle: {
    ...b2,
    width: '90%',
    height: 30,
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    padding: 5,
    marginTop: 20,
  },
  naviheaderTitleStyle: {
    // ...h2,
    fontSize: 24,
    fontWeight: '400',
    marginLeft: 20,
    alignSelf: 'center',
  },
  NextBtnStyle: {
    ...t2,
    color: colors.AppWhite,
  },
  tabBtnViewStyle: {
    ...h3,
    paddingLeft: 5,
    color: colors.Appgray,
  },
  textLabelH3ViewStyle: {
    ...h3,
    color: colors.Appgray,
  },
  subTitleStyle: {
    ...b1,
    color: colors.Appgray,
  },
  filterTitleStyle: {
    ...b2,
    color: colors.AppTitleBlack,
  },
  amountTxtStyle: {
    ...t2,
    textAlign: 'left',
    paddingLeft: 6,
    paddingTop: 10,
  },
  attrbuteSubTypetitleStyle: {
    ...b2,
    textAlign: 'center',
    padding: 5,
    marginTop: 5,
  },
  inviteTxtFieldStyle: {
    ...t1,
    textAlign: 'left',
    borderRadius: 5,
    width: '100%',
    paddingLeft: 8,
    marginTop: -1,
  },
  emptyCatTxtStyle: {
    ...t1,
    color: colors.Appgray,
    height: 40,
    width: '100%',
    paddingTop: 10,
    paddingLeft: 10,
  },
  previewFieldStyle: {
    ...b2,
    color: colors.blackColor,
    marginTop: 8,
  },
  mainStyle: {
    position: 'absolute',
    top: '50%',
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderStyle: {
    flex: 1,
    alignItems: 'center',
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
  },
  txtFieldStyle: {
    ...t1,
    textAlign: alignment,
    paddingLeft: 10,
    marginLeft: itsMobileView ? 0 : 10,
    width: itsMobileView ? '100%' : '80%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    marginTop: itsMobileView ? 5 : 0,
  },
  fulltxtFieldStyle: {
    ...t1,
    textAlign: alignment,
    paddingLeft: 10,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    marginTop: itsMobileView ? 5 : 0,
  },
  appColorFieldStyle: {
    ...t1,
    textAlign: 'left',
    padding: 10,
    marginTop: -10,
    marginLeft: -10,
    height: 40,
    borderRadius: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },

  scheduleTxtStyle: {
    ...t2,
    color: colors.AppTitleBlack,
    margin: 10,
    marginLeft: 0,
  },
  dropDowntxtFieldStyle: {
    ...t1,
    textAlign: alignment,
    paddingLeft: 0,
    margin: 10,
    width: '80%',
  },
  placeholderTxtStyle: {
    ...t1,
    textAlign: alignment,
    paddingLeft: 0,
    margin: 10,
    width: '80%',
    color: colors.placeholderColor,
  },
  listTitleText: {
    ...t1,
    margin: 16,
    textAlign: alignment,
  },
  listItemView: {
    backgroundColor: 'white',
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 5,
    zIndex: 1,
  },
  switchViewStyle: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    zIndex: 1,
  },
  firstSubViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 5,
    zIndex: 1292,
  },
  titleNameStyle: {
    ...t1,
    margin: 10,
    marginLeft: 0,
    textAlign: alignment,
  },
  addImageViewStyle: {
    height: 100,
    width: 100,
    marginTop: itsMobileView ? 10 : 0,
    alignItems: 'center',
    marginLeft: 0,
    justifyContent: 'center',
    // backgroundColor: colors.SimonGray,
    borderRadius: 20,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    borderStyle: 'dashed',
  },
  addImageStyle: {
    flexDirection: 'column',
  },
  ImageViewStyle: {
    height: 100,
    width: 100,
    borderRadius: 20,
    object:"cover"
  },
  dropdownContainer: {
    width: '100%',
    paddingLeft: 10,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: itsMobileView ? '100%' : '80%',
  },
  customTxtFieldViewStyle: {
    padding: 10,
    marginTop: 16,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.AppGreenBorder,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  customTxtFieldStyle: {
    ...t1,
    textAlign: alignment,
    marginTop: -10,
    height: 40,
    borderRadius: 5,
    outlineWidth: 0,
    width: '95%',
    marginLeft: itsMobileView ? 0 : 0,
  },
  showDropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: '100%',
    marginTop: 38,
    marginLeft: 0,
    zIndex: 9000,
    height: 160,
  },
  upArraowIconStyle: {
    width: 15,
    height: 15,
    marginRight: 20,
    alignSelf: 'center',
    transform: [{rotate: '180deg'}],
    marginTop: 0,
  },
  downArraowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
    marginRight: 20,
    marginTop: 0,
  },
  tagViewStyle: {
    marginLeft: itsMobileView ? 0 : 10,
    width: itsMobileView ? '100%' : '80%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    minHeight: 40,
    borderRadius: 5,
    marginTop: itsMobileView ? 10 : 0,
  },
  tagViewContainerStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'flex-start',
    marginBottom: 5,
    zIndex: 1,
  },
  markerContainerViewStyle: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: colors.AppNewGreen,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mapMarkerViewStyle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: colors.BGBlueColor,
  },
  staticTextStyle: {
    ...b2,
    color: colors.Appgray,
  },
  commonBtnTxtStyle: {
    ...button1,
    padding: 10,
    color: colors.AppTitleBlack,
  },
  addButtonOnGetStartTextStyle: {
    ...button2,
    textAlign: 'left',
    marginTop: 10,
    color: colors.AppTitleBlack,
    textDecorationLine: 'underline',
  },
  hintTitleStyle: {
    ...t2,
    textAlign: 'center',
    marginTop: 10,
    color: colors.Lightgray,
  },
  animationViewStyle: {
    backgroundColor: colors.AppLightGreen,
    width: itsMobileView ? windowWidth : 420,
    height: windowHeight,
    padding: 20,
    paddingTop: 10,
  },
  configContainerViewStyle: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    zIndex: 1,
    alignItems: 'flex-start',
  },
  dropdownCellWhiteTextStyle: {
    ...t2,
    color: colors.AppWhite,
    textAlign: 'center',
    width: '100%',
  },
  dropdownCellItemTextStyle: {
    ...t2,
    textAlign: 'left',
    padding: 10,
    color: colors.AppTitleBlack,
  },
  dateTitleStyle: {
    ...t2,
    marginTop: 10,
    marginLeft: 10,
    color: colors.AppTitleBlack,
  },
  accountTypetxtFieldStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 5,
    margin: 10,
    width: '80%',
    color: colors.AppTitleBlack,
    outline: 0,
  },
  accountTypeplaceholderTxtStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 5,
    margin: 10,
    width: '80%',
    color: colors.placeholderColor,
  },
  commissontxtFieldStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 10,
    marginLeft: itsMobileView ? 0 : 10,
    width: itsMobileView ? '100%' : '80%',
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    marginTop: itsMobileView ? 10 : 0,
  },
  commissionValueTxtFieldStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 10,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.Lightgray,
    height: 40,
    borderRadius: 5,
    marginTop: 10,
  },
  editorViewTitleStyle: {
    ...h3,
    alignSelf: 'center',
  },
  subContainerViewStyle: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    marginBottom: 5,
    zIndex: 1,
  },
  previewToolTipStyle: {
    ...b2,
    marginTop: 5,
    color: colors.Lightgray,
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 5,
    marginTop: itsMobileView ? 5 : -5,
    marginBottom: itsMobileView ? 5 : 0,
    height: 36,
    width: itsMobileView ? windowWidth / 2 : 'auto',
    marginRight: 10,
  },
  sidepanSaveBtnViewStyle: {
    alignItems: 'flex-end',
    marginTop: -20,
  },
  sidepanmViewStyle: {
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    marginTop: 25,
  },
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  subMenuTextStyle: {
    ...t1,
    color: colors.AppTitleBlack,
    color: colors.SimonGray,
    paddingLeft: 10,
    width: 160,
  },
  configurationSubViewStyle: {
    marginTop: 30,
    marginRight: 16,
    marginLeft: itsMobileView ? 10 : 20,
    marginBottom: 10,
    width: itsMobileView ? '95%' : '95%',
    height: 500,
  },
  promoMainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    flex: 1,
  },

  cancelViewStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: 80,
    margin: 10,
  },
  closeBtnStyle: {
    width: 15,
    height: 15,
    backgroundColor: 'white',
    borderRadius: 7.5,
  },
});
