import {ICONS} from './Icons';

export let B2CMenus = [
  {
    id: 1,
    title: 'Get Started',
    icon: ICONS.home_Icon,
    sub_menus: [],
    isShow: true,
    href: '/',
  },
  {
    id: 2,
    title: 'Orders',
    icon: ICONS.orderIcon,
    sub_menus: [],
    isShow: true,
    href: '/orders',
  },
  {
    id: 3,
    title: 'Listings',
    icon: ICONS.listing_Icon,
    sub_menus: [
      {
        id: 1,
        title: 'Listings',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/listings',
      },
      {
        id: 2,
        title: 'Categories',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/listing-category',
      },
      {
        id: 3,
        title: 'Custom Attributes',
        icon: ICONS.seoIcon,
        isShow: true,
        href: '/listing-attributes',
      },
      {
        id: 4,
        title: 'Variant Types',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/listing-variants',
      },
      {
        id: 5,
        title: 'Additional Features',
        icon: ICONS.nativeintegrationIcon,
        isShow: true,
        href: '/listing-configs',
      },
      {
        id: 6,
        title: 'Report Listings',
        icon: ICONS.reportedIcon,
        sub_menus: [],
        isShow: true,
        href: '/reported-listings',
      },
    ],
    isShow: true,
  },
  {
    id: 4,
    title: 'Users',
    icon: ICONS.usersIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Users',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/users',
      },
      {
        id: 2,
        title: 'User Attributes',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/user-attributes',
      },
      {
        id: 3,
        title: 'Reviews & Ratings',
        icon: ICONS.reviewIcon,
        sub_menus: [],
        isShow: true,
        href: '/review-ratings',
      },
    ],
    isShow: true,
  },
  {
    id: 5,
    title: 'Report',
    icon: ICONS.reportIcon,
    sub_menus: [],
    isShow: true,
    href: '/reports',
  },
  {
    id: 6,
    title: 'Marketing',
    icon: ICONS.adBanner_Icon,
    sub_menus: [
      {
        id: 0,
        title: 'Collections',
        icon: ICONS.collectionsIcon,
        isShow: true,
        href: '/collections',
      },
      {
        id: 1,
        title: 'Promotions',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/promotions',
      },
      {
        id: 2,
        title: 'Coupons',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/coupon',
      },
    ],
    isShow: true,
  },

  {
    id: 7,
    title: 'Finance',
    icon: ICONS.currencyIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Transactions',
        icon: ICONS.transactionIcon,
        isShow: true,
        href: '/transaction',
      },
      // {
      //   id: 2,
      //   title: 'Settlement',
      //   icon: ICONS.transactionIcon,
      //   isShow: false,
      //   href: '/transaction',
      // },
    ],
    isShow: true,
  },

  {
    id: 9,
    title: 'Media',
    icon: ICONS.mediaIcon,
    sub_menus: [],
    isShow: true,
    href: '/media',
  },

  {
    id: 16,
    title: 'Communication',
    icon: ICONS.communicationIcon,
    sub_menus: [],
    isShow: true,
    href: '/communications',
  },
  {
    id: 11,
    title: 'Web',
    icon: ICONS.webIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Website Editor',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/editor',
        isOpenNewTab: true,
      },
      {
        id: 2,
        title: 'Change Templates',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/templates',
        isOpenNewTab: true,
      },
      // {
      //   id: 3,
      //   title: 'Customise SEO',
      //   icon: ICONS.seoIcon,
      //   isShow: true,
      //   href: '/seo',
      // },
      {
        id: 4,
        title: 'Pages',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/p',
        isOpenNewTab: true,
      },
      {
        id: 5,
        title: 'Blog',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/p',
        isOpenNewTab: true,
      },
      {
        id: 6,
        title: 'Customise texts',
        icon: ICONS.appTranslateIcon,
        isShow: true,
        href: '/strings-customisation',
      },
      {
        id: 7,
        title: 'Add Integrations',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: 'https://tradly.app/workspace/integrations',
        isOpenNewTab: true,
      },
      {
        id: 8,
        title: 'Domain',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/domain',
        isOpenNewTab: true,
      },
    ],
    isShow: true,
  },

  {
    id: 12,
    title: 'App',
    icon: ICONS.appIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Buy App Templates',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/templates',
        isOpenNewTab: true,
      },
      {
        id: 2,
        title: 'Customisation',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/app-customization',
      },
      {
        id: 3,
        title: 'Integrations',
        icon: ICONS.nativeintegrationIcon,
        isShow: true,
        href: '/app-integration',
      },
      {
        id: 4,
        title: 'Customise texts',
        icon: ICONS.appTranslateIcon,
        isShow: true,
        href: '/strings-customisation',
      },
    ],
    isShow: true,
  },

  //   {id: 16, title: 'More', icon: moreIcon, sub_menus: [], isShow: true},
];
